import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '../document.service';
import { ApiService } from 'app/main/service/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPaymentComponent } from 'app/main/components/modal-payment/modal-payment.component';
import { DocumentApiService } from 'app/main/service/document-api.service';
import { ComponentsService } from 'app/main/components/components.service';
import { DocumentStatus } from 'app/main/model/DocumentStatus';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
import { environment } from 'environments/environment';
import { PaymentMethodList } from 'app/main/model/PaymentMethod';

@Component({
  selector: 'app-goods-receipt',
  templateUrl: './goods-receipt.component.html',
  styleUrls: ['./goods-receipt.component.scss'],
})
export class GoodsReceiptComponent implements OnInit {
  contentHeader: object;
  componentName: string;
  currentDate: string;
  apiPath: string;
  pathUrl: string;
  editPathUrl: string;
  detailPathUrl: string;
  itemList: any[] = [];
  typeDocName: string;
  searchParams: any = {};
  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  activeStatus: string;
  selectedColumns: string[];
  objectExport: any;
  searchName: string;
  searchId: string;
  activeTab: string = 'all';
  docStatus = DocumentStatus;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  currentLang: string;

  isJoinIV_TX_RE: boolean = false;

  isPaymentModalOpening: boolean = false;

  statusList: any[] = [];

  searchData: any = {};
  page: number = 1;
  pageSize: number = 10;

  constructor(
    private _translateService: TranslateService,
    private _documentService: DocumentService,
    private _apiService: ApiService,
    private _globalFuncService: GlobalFuncService,
    private _modalService: NgbModal,
    private _documentApiService: DocumentApiService,
    private _componentsService: ComponentsService
  ) {
    this.apiPath = this._documentService.GoodsReceive.ApiPath;
    this.pathUrl = this._documentService.GoodsReceive.PathURL;
    this.detailPathUrl = this._documentService.GoodsReceive.DetailPathURL;
    this.selectedColumns = [
      'customerId',
      'invoiceCode',
      'documentCreateDate',
      'documentExpireDate',
      'grandTotal',
    ];

    this.statusList = [
      {
        value: { SortPath: this.apiPath + 'Code', Direction: 1 },
        name: 'all',
        translate: 'General.All',
      },
      {
        value: {
          SortPath: this.apiPath + 'Code',
          Direction: 1,
          TransactionStatusId: 4,
          isActive: true,
          isDelete: false,
        },
        name: 'awaitPayment',
        translate: 'Status.AwaitPayment',
      },
      {
        value: {
          SortPath: this.apiPath + 'Code',
          Direction: 1,
          TransactionStatusId: 5,
          isActive: true,
          isDelete: false,
        },
        name: 'active',
        translate: 'Status.Paid',
      },
      {
        value: {
          SortPath: this.apiPath + 'Code',
          Direction: 1,
          isDelete: false,
          isVoid: true,
        },
        name: 'delete',
        translate: 'Status.Voided',
      },
    ];

    this.isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
    this.initData();
  }

  initData(): void {
    this.typeDocName = 'goodsReceiveCode'; //code
    this.SetLoadingState();
    this.searchData = {
      SortPath: this.apiPath + 'Code',
      Direction: 1,
      isDelete: false,
    };
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  getAllData(params?) {
    this.loadData(params);
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    } else {
      this.searchData = {
        SortPath: this.apiPath + 'Code',
        Direction: 1,
        isDelete: false,
      };
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }

  getDatabyState(state, tab: string): Promise<any> {
    var self = this;
    self.isLoading = true;
    self.activeTab = tab;
    const searchParams = this.searchParams;

    return new Promise((resolve, reject) => {
      const combinedParams = {
        ...state,
        ...searchParams?.params,
      };

      this._apiService
        .GetAllData(this.apiPath, combinedParams)
        .subscribe((res) => {
          self.itemList = res.data.resultData;
          console.log(this.itemList);
          self.isLoading = false;
          self.SetLoadedState();
          resolve(self.itemList);
        }, reject);
    });
  }

  ngOnInit(): void {
    var self = this;
    this.contentHeader = {
      headerTitle: 'Document.GoodsReceive',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Document',
            isLink: true,
            link: '/',
          },
          {
            name: 'Document.GoodsReceive',
            isLink: false,
            link: '/document/goods-receive/view',
          },
        ],
      },
    };

    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: this.pageSize,
      order: [[1, 'desc']],
      ordering: true,
      serverSide: true,
      lengthMenu: [10, 25, 50, 100],
      lengthChange: true,
      dom: '<"float-left"B><"float-right"f>rt<"row"<"col-sm-4"i><"col-sm-4 text-center"l><"col-sm-4"p>>',
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: this.apiPath + 'Code', targets: 1 },
        { name: 'CustomerName', targets: 2 },
        { name: 'TransactionStatusId', targets: 3 },
        { name: 'DocumentCreateDate', targets: 4 },
        { name: 'DocumentExpireDate', targets: 5 },
        { name: 'GrandTotal', targets: 6 },
        { orderable: false, targets: 7 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemList = [];

        self.SetLoadingState();

        let defaultSortName = this.apiPath + 'Code';
        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );

        this.page = this.searchData.Page;

        this.searchData.PageLength = dataTablesParameters.length;
        this.searchData.isDelete = false;

        this._apiService
          .GetAllData(this.apiPath, this.searchData)
          .subscribe((response) => {
            console.log(response);
            this.itemList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          });
      },
    };
  }

  ExportXLSX() {
    const selectedColumns = this.selectedColumns;
    this.objectExport = this.itemList;
    const headers = Object.keys(this.objectExport[0]);

    const excludedColumnIndices = headers
      .map((header, index) => (selectedColumns.includes(header) ? -1 : index))
      .filter((index) => index !== -1);

    let filteredTableData = this.objectExport;
    console.log(selectedColumns.length);

    if (selectedColumns.length > 0) {
      filteredTableData = this.objectExport.map((row, index) => {
        const filteredRow = { 'No.': index + 1 };
        Object.entries(row).forEach(([columnName, cell], index) => {
          if (!excludedColumnIndices.includes(index)) {
            filteredRow[columnName] = cell.toString().trim();
          }
        });
        return filteredRow;
      });
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredTableData);

    /* new format */
    var fmt = '0.00';
    /* change cell format of range B2:D4 */
    var range = { s: { r: 1, c: 1 }, e: { r: 2, c: 100000 } };
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        if (!cell || cell.t != 'n') continue; // only format numeric cells
        cell.z = fmt;
      }
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    var fmt = '@';
    wb.Sheets['Sheet1']['F'] = fmt;
    console.log(wb);

    /* save to file */
    XLSX.writeFile(wb, `${'Sheet'}.xlsx`);
  }

  exportXLSX() {}

  setActiveContent(val, status): void {
    console.log(val.isActive);
    this._apiService.SetIsActiveData(this.apiPath, val.id, status).subscribe(
      (res) => {
        this._componentsService.SuccessSwal();
        this.isLoading = true;
        this.initData();
        console.log(val);
      },
      (err) => {
        this._componentsService.ErrorSwal();
      }
    );
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  async getNetTotal(docId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._documentApiService.GetNetTotal(this.apiPath, docId).subscribe(
        (res) => {
          console.log(res);
          resolve(res.data.resultData[0]);
        },
        (error) => {
          reject(error); // Handle any error that might occur during the API call
        }
      );
    });
  }

  openPaymentModal(objData?: any): void {
    this.isPaymentModalOpening = true;
    console.log(this.isPaymentModalOpening);
    this.getNetTotal(objData.id)
      .then((netData) => {
        const modalRef = this._modalService.open(ModalPaymentComponent, {
          centered: true,
          backdrop: 'static',
          size: 'lg',
        });

        modalRef.componentInstance.objData = objData;
        modalRef.componentInstance.netData = netData;
        modalRef.componentInstance.docCodeColumn = this.typeDocName;
        modalRef.componentInstance.isGoodsReceive = false;

        this.isPaymentModalOpening = false;
        console.log(this.isPaymentModalOpening);

        modalRef.componentInstance.callBackFunc.subscribe((res) => {
          let isTX = this.isJoinIV_TX_RE ? true : false;
          let isRE = this.isJoinIV_TX_RE ? true : false;

          this.blockUI.start();
          let params = {
            paymentTypeId: res.paymentTypeId,
            total: res.payAmount,
            bankAccountId: res.bankAccountId,
            salesWHT: res.salesWHT,
            remarks: res.remarks,
            isTaxInvoice: isTX,
            isReceipt: isRE,
          };

          if (params.paymentTypeId == PaymentMethodList.Cash.id) {
            this._documentApiService
              .CashPayment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  console.log(res);

                  this.getAllData();

                  const titleLinkDocButton =
                    this._translateService.instant('Alert.GoToRC');

                  this._componentsService.SuccessSwal(undefined, undefined);
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          } else if (params.paymentTypeId == PaymentMethodList.Transfer.id) {
            this._documentApiService
              .TransferPayment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  console.log(res);

                  this.getAllData();

                  const titleLinkDocButton =
                    this._translateService.instant('Alert.GoToRC');

                  this._componentsService.SuccessSwal(undefined, undefined);
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          } else {
            this._documentApiService
              .Payment(this.apiPath, res.docId, params)
              .subscribe(
                (res) => {
                  this.getAllData();
                  this._componentsService.SuccessSwal();
                },
                (err) => {
                  this._componentsService.ErrorSwal();
                }
              );
          }
        });
      })
      .catch((error) => {
        console.error('Error occurred:', error);
      });
  }
}
