export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://repoveuat-api.appintouch.net/api',
  appName: 'REPOVE Back Office',
  appTitle: 'REPOVE Back Office',
  appLogoImage: 'assets/images/logo/repove-blue.png',
  appLogoNav: 'assets/images/logo/repove-blue.png',
  defaultIMG: 'assets/images/general/repove_default_img.png',

  // language list for content
  langDefault: 'en',
  langContent: ['en', 'th'],

  transaction: {
    issuerData: {
      name: 'Cocueco (Thailand) Co., Ltd.',
      branch: 'สำนักงานใหญ่',
      email: 'sales@cocueco.com',
      taxID: '0105561167749',
      website: 'https://www.repove.world/',
      telephone: '081 234 5679',
      address: '75/4 ซอยสุขุมวิท 64/1, แขวงพระโขนงใต้, เขตพระโขนง, กรุงเทพมหานคร 10260'
    },
    logoOnPaperPath: '../assets/images/logo/Logo-Coceuco-TH-compressed.png',
    logoOnFooterLeftPaperPath:
      '../assets/images/logo/Logo-Coceuco-TH-compressed.png',
    logoOnFooterRightPaperPath: '../assets/images/logo/repove-blue.png',
    showLogoFooter: true,
    isUseWht: false,
    isSplitIVfromSO: false,
    isSplitGRfromPO: false,
    isJoinIV_TX_RE: true,
    isQuantityDecimal: false,
    shippingFee: 150,
  },

  stock: {
    isQuantityDecimal: false,
  },

  customer: {
    requiredDetail: false,
    requiredCentralContact: false,
  },

  limitFileSize: {
    maxImageSizeInBytes: 500 * 1024, //300KB
    maxVideoSizeInBytes: 2 * 1024 * 1024, //2MB
    maxFileSizeInBytes: 2 * 1024 * 1024, //2MB
  },

  menuList: {
    dashboard: true,
    itemManage: true,
    cms: true,
    customer: true,
    transactionDoc: true,
    stock: true,
    mlm: true,
    codeManage: true,
    returnProduct: true,
    notification: true,
    bookingCalendar: true,
  },

  systemSettings: {
    useCRUDMasterAddr: false,
  },
};
