import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import FAQ from 'app/main/model/FAQ';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public config: ICMSConfig = {
    apiPath: 'Faq',
    pathUrl: '/cms/faq',
    tableName: 'Content',
    model: new FAQ(),
    isList: true,
    componentName: 'BreadCrumbs.CMS.FAQ',
    formConfig: {
      name: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'FAQ Name',
        description: 'ชื่อ',
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Question',
        description: 'คำถาม',
        isMultipleLang: true,
      },
      subTitle: {
        type: FormType.Textarea,
        validator: [Validators.required],
        label: 'Answer',
        description: 'คำตอบ',
        isMultipleLang: true,
      },
    },
  }

  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'name';

  constructor() { }
}
