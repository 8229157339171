export const locale = {
  lang: 'en',
  data: {
    autoForm: {
      typeAnyKey: 'Type any key......',
    },
    Alert: {
      lang: 'This field is not yet specified in : ',
      Success: 'Success',
      Confirm: 'Confirm',
      Error: 'Error',
      SomethingWentWrong: 'Something went wrong',
      Invalid: 'Please complete the required information.',
      Approved: 'Approved',
      ApproveFail: 'Approve Fail',
      PleaseCancelVoid: 'Please cancel or void related documents first',
      GetReceiptSuccess: 'Get Receipt Success',
      VoidGotReceiptSuccess: 'Void got receipt success',
      CancelDocumentSuccess: 'Cancel Document Success',
      VoidDocumentSuccess: 'Void Document Success',
      SignUpComplete: 'Sign Up Complete',
      VerifyEmailSendSuccessfully: 'Verify Email Send Successfully',
      EmailNotExist: 'Email Not Exist',
      PleaseTryAgain: 'Please try again',
      FormValidationFailed: 'Form Validation Failed',
      ImageUploadSuccessfully: 'Image Upload Successfully',
      EmailResetPasswordHasSent: 'Email reset password link has been sent',
      PasswordHasBeenReset: 'Password has been reset',
      max: 'Max',
      files: 'files',
      maximize: 'The number that can be added has been reached.',
      ChooseFiles: 'Choose Files',
      Cancel: 'Cancel',
      CodeQO: 'Quotation : {{docCode}}',
      GoToSO: 'Go to Sale Order',
      CodeSO: 'Sale Order : {{docCode}}',
      GoToIV: 'Go to Invoice',
      CodeIV: 'Invoice : {{docCode}}',
      GoToRC: 'Go to Receipt',
      CodeRC: 'Receipt : {{docCode}}',
      GoToTX: 'Go to TAX Invoice',
      CodeTX: 'TAX Invoice : {{docCode}}',
      CodePR: 'Purchase Request : {{docCode}}',
      GoToPO: 'Go to Purchase Order',
      CodePO: 'Purchase Order : {{docCode}}',
      GoToGR: 'Go to Goods Receive',
      CodeGR: 'Goods Receive : {{docCode}}',
    },
    Modal: {
      ConfirmTheCancellation: 'Confirm the cancellation ',
      AreYouSureToCancellation:
        'Are you sure you want to cancellation {{title}} No.{{docCode}} ?',
      ConfirmTheCreateTaxInvoice: 'Confirm the create Tax Invoice',
      AreYouSureToCreateTaxInvoice:
        'Are you sure to create Tax Invoice from Invoice ?',
      CancelCreateSeparateIV: 'Cancel Separate Invoice Creation',
      AreYouSureToCancelCreateSeparateIV:
        'Are you sure to cancel the creation of a separate invoice ?',
      CancelCreatePR: 'Cancel Purchase Request Creation',
      AreYouSureToCancelCreatePR:
        'Are you sure to cancel the creation of a purchase request ?',
      CancelCreateSeparateGR: 'Cancel Separate Goods Receive Creation',
      AreYouSureToCancelCreateSeparateGR:
        'Are you sure to cancel the creation of a separate goods receive ?',
      CancelEditPR: 'Cancel to Edit Purchase Request',
      AreYouSureToCancelEditPR:
        'Are you sure to cancel editing the purchase request ?"',
      SaveDraftPR: 'Save Purchase Request Draft',
      AreYouSureToSaveDraftPR: 'Are you sure to save purchase request draft ?',
      CreateSeparateIV: 'Create Separate Invoice',
      AreYouSureToCreateSeparateIV: 'Are you sure to create separate invoice ?',
      CreateSeparateGR: 'Create Separate Goods Receive',
      AreYouSureToCreateSeparateGR:
        'Are you sure to create separate goods receive ?',
      CreatePR: 'Create Purchase Request',
      AreYouSureToCreatePR: 'Are you sure to create purchase request ?',
      ConfirmGetReceipt: 'Confirm Receipt',
      AreYouSureToGetReceipt: 'Are you sure you want to get the receipt ?',
      ConfirmVoidGotReceipt: 'Confirm to Void Got Receipt',
      AreYouSureToVoidGotReceipt: 'Are you sure you want to void got receipt ?',
      ApprovePO: 'Approve Purchase Order',
      AreYouConfirmToApprovePO:
        'Are you confirm to approve this Purchase Order ?',
      CancelManagePage: 'Unsaved Changes',
      CancelManage: 'Cancel Manage ',
      PressSubmitToCancel: 'Are you sure to unsaved changes of these data',
      ConfirmEditPage: 'Confirm Edit ',
      ConfirmEdit: 'Confirm Edit ',
      AreYouSureToEditPage: 'Are you sure to editing ',
      AreYouSureToEdit: 'Are you sure to editing ',
      ConfirmCreate: 'Confirm Saving Data',
      AreYouSureToCreate: 'Are you sure to saved changes of these data?',
      ConfirmEditNewsLandingPage: 'Confirm Edit News Landing Page',
      AreYouSureToEditNewsLandingPage:
        'Are you sure to editing News Landing Page',
      CancelManageNewsLandingPage: 'Cancel Manage News Landing Page',
    },
    ErrorMessage: {
      QuantityMustNotBeNegative:
        '*Quantity of item/service must not be negative.',
      QuantityMustNotBeNegativeOrExceedTheRemaining:
        '*Quantity of products/services must not exceed the remaining quantity or be negative.',
      ProductPriceMustNotBeNegative: '*Product price must not be negative.',
      ProductPriceMustNotBeNegativeOrExceedTheOriginalAmount:
        '*Product price must not exceed the original amount or be negative.',
      DiscountMustNotBeNegativeOrExceedTheProductPrice:
        '*Discount amount must not exceed the product price or be negative.',
      TotalDiscountMustNotBeNegative:
        '**Total discount amount must not be negative.',
      TotalDiscountMustNotExceed: '**Total discount amount must not exceed',
      AndMustNotBeNegative: 'and must not be negative.',
      GrandTotalAmountMustNotBe0Baht:
        '**Grand total amount must not be less than 0 Baht.',
      QuantityGreaterThanRemaining:
        '*Quantity of item/service is greater than the remaining.',
      DescNotFirstRow:
        'Description row cannot be the top of the first item/service in the row.',
      PleaseSelectItem: '*Please select item fisrt.',
    },
    Handle: {
      Name: 'Name is required',
      Exceed: 'Cannot exceed',
      Char: 'characters',
      Group: 'Group is required',
      Title: 'Title is required',
      SubTitle: 'Subtitle is required',
      Category: 'Category is required',
      Class: 'SubClass is required',
      classId: 'Class is required',
      Detail: 'Detail is required',
      ExceedChar: 'Name cannot exceed 50 characters.',
      UserName: 'Username is required',
      ExceedCharUserName: 'Username cannot exceed 100 characters.',
      Email: 'Email is required',
      EmailSyntax: 'Email syntax is incorrect.',
      LastName: 'Last Name is required',
      ExceedCharLastName: 'Last Name cannot exceed 100 characters.',
      ExceedCharName: 'Name cannot exceed 100 characters.',
      Description: 'Description is required',
      DescriptionAllLanguage: 'Description is required in all language.',
      DetailAllLanguage: 'Detail is required in all language.',
      SingleImage: 'Image is required',
      MutipleFile: 'File is required',
      FirstLetterInvalid: 'Special character not allow at first charactor.',
      CodeName: 'Promotion name required',
      RedeemCode: 'Redeem Code is required',
      Amount: 'Amount is required',
      Discount: 'Discount is required',
      AmountPerCustomer: 'Amount per customer is required',
      StartDate: 'Start date is required',
      EndDate: 'End date is required',
      noWhitespaceValidator: 'No whitespace allowed',
    },
    General: {
      Download: 'Download',
      Save: 'Save',
      Action: 'Action',
      PressToCancel: 'Press the confirm button to cancel.',
      CancelAction: 'Cancel action related to ',
      CancelEdit: 'Cancel to edit',
      CancelCreate: 'Cancel to create ',
      Cancel: 'Cancel ',
      Confirm: 'Confirm ',
      General: 'General',
      Default: 'Default',
      Deprecated: 'Deprecated',
      Detail: 'Detail',
      Date: 'Date',
      Total: 'Total',
      Buy: 'Buy',
      Baht: 'Baht',
      Sell: 'Sell',
      OrderHistory: 'Order History',
      RankUpHistory: 'Rank History',
      Copy: 'Copy',
      Gender: 'Gender',
      Check: 'Check',
      Quotation: 'Quotation',
      AreYouConfirm: 'Are you confirm ',
      toCreate: 'to create ',
      toEdit: 'to edit ',
      Home: 'Home',
      Admin: 'Admin',
      Profile: 'Profile',
      ACLManage: 'Manage ACL',
      UserManage: 'Manage User',
      RolesManage: 'Manage Roles',
      BannerManage: 'Manage Banner',
      BrandManage: 'Manage Brand',
      Brand: 'Brand',
      NewsManage: 'Manage News',
      Item: 'Item',
      ContentManage: 'Manage Content',
      ItemManage: 'Manage Item',
      ItemSetManage: 'Manage Item Set',
      GroupManage: 'Manage Group',
      CategoryManage: 'Manage Category',
      SubCategoryManage: 'Manage SubCategory',
      ClassManage: 'Manage Class',
      SubClassManage: 'Manage SubClass',
      Purchasing: 'Purchasing',
      PurchaseOrder: 'Purchase Order',
      ControlPanel: 'Control Panel',
      RelatedDocument: 'Related Document',
      Create: 'Create',
      Edit: ' Edit',
      Delete: 'Delete',
      Add: 'Add ',
      Export: 'Export',
      Manage: 'Manage',
      PDPA: 'PDPA',
      Event: 'Event',
      Search: 'Search',
      SearchBy: 'Search By',
      SearchKeyword: 'Search',
      SortBy: 'Sort By',
      Status: 'Status',
      All: 'All',
      Draft: 'Draft',
      Approve: 'Approve',
      Rejects: 'Rejects',
      Bin: 'Bin',
      Previous: 'Previous',
      Next: 'Next',
      Change: 'Change',
      Settings: 'Settings',
      Group: 'Group',
      Category: 'Category',
      SubCategory: 'Sub-Category',
      Class: 'Class',
      SubClass: 'Sub-Class',
      ItemManagement: 'Item',
      Customer: 'Customer',
      Sale: 'Sale',
      Account: 'Customer',
      Trash: 'Trash',
      Document: 'Documents',
      RevenueDocument: 'Revenue Documents',
      ExpensesDocument: 'Expenses Documents',
      Revenue: 'Revenue',
      Exoenses: 'Exoenses',
      ViewAll: 'View All',
      Stock: 'Stock',
      StockManage: 'Stock Manage',
      Reset: 'Reset',
      Back: 'Back',
      Expired: '(Expired)',
      DocumentInfo: 'Document',
      SlipInfo: 'Slip',
      PaymentInfo: 'Payment',
      HistoryInfo: 'History',
      Percent: 'Percent',
      Remain: 'Remain',
      InStock: 'Stock',
      Original: 'Original',
      Product: 'Product',
      OurTechnology: 'Our Technology',
      About: 'About',
      NewsEvent: 'News & Event',
      LandingPage: 'Landing Page',
      ConsumerReview: 'Consumer Review',
      HelpCenter: 'Help Center',
      BonusPlan: 'Bonus Plan',
      FAQ: 'FAQ',
      CompanyVideo: 'Company Video',
      Contact: 'Contact',
      PrivacyPolicy: 'Privacy & Policy',
      CookiePolicy: 'Cookie Policy',
      TermsCondition: 'Terms & Condition',
      SellerManage: 'Seller Management Regulation',
      ShoppingCart: 'Shopping Cart',
      SignIn: 'Sign In',
      RoleSet: 'Role',
      ValueWaitToBePaid: 'Value waiting to be paid',
      ValueWaitToPaid: 'Value waiting to be paid',
      ReceivePayment: 'Receive Payment',
      Payment: 'Payment',
      SystemSettings: 'System Settings',
      Downline: 'Downline',
      SaleDashboard: 'Sale Dashboard',
      DeliveryProvider: 'Delivery Provider',
      Cod: 'Cash on (Cod)',
      TransferPayment: 'Transfer payment',
      ManageCode: 'Manage Code',
      PromotionCode: 'Promotion Code',
      PromotionCodeManage: 'Manage Promotion Code',
      ConfirmReceivePayment: 'Confirm receipt of money on destination',
      AreYouSureToReleaseCOD:
        'Are you sure to release products with COD payment? \nIf canceled, this transaction will be canceled immediately.',
      ConfirmCancelOrder: 'Confirm to cancel order',
      ConfirmCancelOrdertitleDetail:
        'Do you confirm to cancel this transaction?',
      Success: 'Success',
      CancelOrderSuccess: 'Cancel order success',
      PaymentType: 'Payment Type',
      CashOnDelivery: 'Cash on Delivery',
      BankTransfer: 'Bank Transfer',
      RepoveToolsLandingPage: 'Landing Page',
      TechProductLandingPage: 'Tech and Product Landing Page',
      OurCompany: 'Our Company',
      Timeline: 'Timeline',
      CompanyCard: 'Company Card',
      OfficeBranch: 'Branch',
      Booster: 'Booster',
      Serum: 'Serum',
      Cream: 'Cream',
      SunProtector: 'Sun Protector',
      Bodywash: 'Bodywash',
      Technology: 'Technology',
      Highlight: 'Highlight',
      ETCTechnology: 'ETC Technology',
      Certification: 'Certification',
      CertificationCard: 'Cert Card',
      CertificationFile: 'Cert File',
      Highest: 'Highest',
      Lowest: 'Lowest',
      ProductDetail: 'Product Detail',
    },
    Form: {
      Name: 'Name',
      Barcode: 'Barcode',
      Description: 'Description',
      Detail: 'Detail',
      Status: 'Status',
      Quantity: 'Quantity',
      purchasePrice: 'Purchase Price',
      Price: 'Price',
      SellingPrice: 'Selling Price',
      Group: 'Group',
      Images: 'Images',
      Cancel: 'Cancel',
      Confirm: 'Confirm',
      Submit: 'Submit',
      RefText1: 'ref text',
      Title: 'Title',
      Subtitle: 'Subtitle',
      Details: 'Details',
      ButtonCaption: 'Button Caption',
      ButtonURL: 'Button URL',
      Files: 'Files',
      FieldRequired: 'This field is required!',
      Attract: 'Attract Files',
      Cover: 'Cover',
      SaveDraft: 'Save Draft',
      ApproveDoc: 'Approve',
      CreateGRFormPO: 'Create Goods Receive from Purchase Order',
      IsShowing: 'Show on Website',
      IsBestSeller: 'Best Seller',
      IsNewProduct: 'New Item',
      EnterDescription: 'Enter Description...',
      EnterName: 'Enter Name...',
      BeginTypeHere: 'Begin typing here...',
      Required: 'Required this field',
    },
    PromotionForm: {
      PromotionName: 'Promotion name',
      RedeemCode: 'Redeem code',
      Amount: 'Amount',
      UnlimitedCode: 'Unlimited code',
      Discount: 'Discount',
      PercentDiscount: 'Percent discount',
      NumberCanReuse: 'Codes that customers can reuse',
      UnlimitedReusable: 'Unlimited reusable discounts',
      StartDate: 'Start date',
      EndDate: 'End date',
      DiscountNoExpire: 'The discount never expires',
      CancelEditTitle: 'Cancel to edit Promotion Code',
      CancelEdit: 'Are you sure to cancel to edit Promotion Code?',
      CancelAddTitle: 'Cancel to add Promotion Code',
      CancelAdd: 'Are you sure to cancel to add Promotion Code?',
      ConfirmEditTitle: 'Confirm to edit Promotion Code',
      ConfirmEdit: 'Are you sure to edit Promotion Code?',
      ConfirmAddTitle: 'Confirm to add Promotion Code',
      ConfirmAdd: 'Are you sure to add Promotion Code?',
    },
    ProductForm: {
      FormTitle: 'Product',
      Product: 'Manage Product',
      Title: 'Title',
      Detail: 'Detail',
      Icon: 'Icon',
      Label: 'Label',
      UsingDetailList: 'Use Detail List',
      UsingLabel: 'Use Label',

      PCRow: 'PC Row Position',
      PCColumn: 'PC Column Position',
      MBRow: 'Mobile Row Position',
      MBColumn: 'Mobile Column Position',
      LandingCoverImage: 'Landing Cover Image',
      PresenterImage: 'Presenter Image',
      TechnologyCoverImage: 'Technology Cover Image',
      ProductImage: 'Product Image',
      ThaiName: 'Product name in Thai',
      EnglishName: 'Product name in English',
      Price: 'Price',
      Volume: 'Volume',
      ProductDetail: 'Product Detail',

      DetailIcon: 'Detail Icon',
      TechnologyDetail: 'Technology Detail',
      FeatureTechTitle: 'Feature Technology Title',
      OtherFeatureTechTitle: 'Other Feature Technology Title',
      ComponentDetail: 'Component Detail',
      TestResult: 'Test Result',
      TBTestResult: 'Test Result for Tablet',
      MBTestResult: 'Test Result for Mobile',

      BeforeAfterImage: 'Before and After Image',
    },

    ProductDetailForm: {
      Product: 'Manage Product Detail',
      ProductDetail: 'Product Detail',
      ItemIdName: 'Product',
      ItemName: 'Detail Name',
      HowItWorkTitle: 'How It Work Title',
      HowItWorkDetail: 'How It Work Detail',
      ActiveIngredientsTitle: 'Active Ingredients Title',
      ActiveIngredientsDetail: 'Active Ingredients Detail',
      HowToUseTitle: 'How To Use Title',
      HowToUseDetail: 'How To Use Detail',
    },

    Status: {
      Active: 'Active',
      Inactive: 'Inactive',
      Published: 'Published',
      Unpublished: 'Unpublished',
      Publish: 'Publish',
      Unpublish: 'Unpublish',
      Draft: 'Draft',
      AwaitApproval: 'Wait Approval',
      Accepted: 'Accepted',
      AwaitPayment: 'Wait Payment',
      Paid: 'Paid',
      Expired: 'Expired',
      Overdue: 'Overdue',
      Voided: 'Voided',
      Cancel: 'Cancel',
      GotReceipt: 'Got Receipt',
      Pending: 'Pending',
      Complete: 'Complete',
      WaitingAdjust: 'Waiting Adjust',
    },
    BonusPlan: {
      Active: 'Are you sure to ',
      DeleteTitle: 'Delete ',
      Delete: 'Are you sure to delete ',
      UpdateTitle: 'Update ',
      Update: 'Are you sure to update ',
      AddTitle: 'Add ',
      Add: 'Are you sure to add ',
      PersonalBonus: 'Personal Bonus',
      DownlineBonus: 'Downline Bonus',

      SystemBonus: 'System Bonus',
      Condition: 'Condition',
      BonusCondition: {
        PersonalSale: 'Personal Sale 30,000 + SV in 1 Period',
        JoinBonus: 'Join 100%',
        MPBonus: 'MP Bonus',
      },
      SystemBonusHistory: 'System Bonus History',
      SVHistory: 'SV History',
      UpdateBy: 'Update By',
      UpdateDate: 'Update Date',

      SVExchange: 'SV Exchange Rate',
      EditSV: 'Edit Exchange Rate',

      BonusPlanManage: 'Bonus Plan Manage',
      PersonalBonusManage: 'Personal Bonus Table',
      DownlineBonusManage: 'Downline Bonus Table',
      SystemBonusManage: 'System Bonus Table',
      EventBonusManage: 'Event Bonus Manage',

      Status: {
        InActive: 'InActive',
        Active: 'Active',
        Create: 'Create',
        Deleted: 'Deleted',
      },
    },

    EKYC: {
      Bookbank: 'Bookbank Image',
      PIDImage: 'ID Card Image',
      PIDWithPersonImage: 'Person with ID Card Image',
      EditData: 'Edit e-KYC Data',
      Data: 'Data',
      History: 'History',
      Table: {
        Date: 'Date',
        Detail: 'Detail',
        File: 'File',
        UpdatedBy: 'Updated By',
        BankAccountName: 'Bank Acc. Name',
        BankAccountNo: 'Bank Acc. No.',
        BankName: 'Bank',
      },
      BankAccount: 'Bank Account History',
      FileHistory: 'File History',
      IdCardImage: 'ID Card',
      PersonalWithIdCardImage: 'Personal With ID Card',
      BookbankImage: 'Book bank',
      BookbankDetail: 'Book bank',
    },
    ReturnProduct: {
      Refund: 'Refund',
      ReturnProduct: 'Return Request',
      ReturnRequest: 'Return Request',
      SaveRequestModalTitle: 'Save Request',
      SaveRequestModalDetail:
        'Are you sure to save this request with these data?',
      ReturnStatus: 'Request Status',
      RequestBy: 'By',
      CreateDate: 'Create Date',
      RequestDate: 'Request Date',
      Remarks: 'Remarks',
      Reasons: 'Reasons',
      OtherReasons: 'Other Reasons',
      CheckedBy: 'Checked By',
      ReturnType: 'Return Type',
      AttachmentFile: 'Attachment File',
      CheckBeforeSave:
        'Please check the information. Once the request is saved, it will not be possible to come back and edit it again.',
      InvoiceCode: 'Invoice Code',
      RequestDetail: 'Request Detail',
      ItemList: 'Item List',
      Claim: 'Claim',
      ClaimModalTitle: 'Claim',
      ClaimModalDetail:
        'Do you want to receive a claim for the products listed? When the claim is received, the product balance will be returned to the claimed product stock.',
    },
    Sort: {
      AscChar: 'A-Z',
      DescChar: 'Z-A',
      Newest: 'Newest',
      Oldest: 'Oldest',
    },
    Menu: {
      Document: {
        QO: 'Quotation (QO)',
        SO: 'Sale Order (SO)',
        IV: 'Invoice (IV)',
        TX: 'TAX Invoice (TX)',
        RC: 'Receipt (RC)',
        PR: 'Purchase Request (PR)',
        PO: 'Purchase Order (PO)',
        GR: 'Goods Receive (GR)',
        CNT: 'Credit Note (CNT)',
        DBN: 'Debit Note (DBN)',
      },
      Profile: 'Profile',
      Settings: 'Settings',
      Logout: 'Logout',
      FrontendControlPanel: 'Frontend Control Panel',
    },

    Dashboard: {
      Title: 'Dashboard',
      ExecutiveSummaryDashboard: 'Executive Summary Dashboard',
      RevenueData: 'Revenue Data',
      MemberData: 'Member Data',

      BookingCalendar: 'Booking Calendar',
      BookingCalendarData: 'Booking Calendar Data',
      ApproveBookingList: 'Approve Booking List',
      ReturnProductRequest: 'Return Request',
      Revenue: {
        Title: 'Revenue',
        Month: 'Month',
        Target: 'Target',
        Year: 'Year',
        TargetValue: 'Target Value',
        Classifier: 'Classifier',

        Baht: 'Baht',

        TargetAccum: 'Target Accum',
        TargetSales: 'Target Sales',

        Item: 'Item',
        SelectItem: 'Select Item',
      },
      Member: {
        Title: 'Member',
        TrackingTitle: 'Member Data Trackings',
        Month: 'Month',
        Target: 'Target',
        Year: 'Year',
        TargetValue: 'Target Value',
        Classifier: 'Classifier',
        NewMemberTarget: 'New Member Target',
        ExistingMemberTarget: 'Existing Member Target',

        Member: 'Member',
        BestAllTime: 'Top 5 Best Performance All Time',
        WorstAllTime: 'Top 5 Worst Performance All Time',
        BestInMonth: 'Top 5 Best Performance In',
        WorstInMonth: 'Top 5 Worst Performance In',
      },
      ConversionRate: 'Conversion Rate',
      MPVSRevenue: 'MP VS Revenue',
      YOY: {
        Title: 'YOY',
        Compare: 'Compare',
        With: 'With',
      },
    },

    Settings: {
      General: 'General',
      BankAccount: 'Bank Account',
      DocumentInfo: 'Document Info',
      Copied: 'Copied!',
      CopyBankAccountToClipboard:
        'Bank Account Number was copied to clipboard.',
      Modal: {
        GeneralConfirmTitle: 'Confirm to save Document info',
        GeneralConfirmDetail:
          'Are you sure to saving Document info with these data?',
        SetDefaultBankAccountTitle: 'Confirm to set Default Bank Account',
        SetDefaultBankAccountDetail:
          'Are you sure to saving this Bank Account to Default?',
        RemoveDefaultBankAccountTitle: 'Confirm to remove Default Bank Account',
        RemoveDefaultBankAccountDetail:
          'Are you sure to remove this Default Bank Account?',
      },
      DefaultBankAccount: 'Default Bank Account',
      AllBankAccount: 'All Bank Account',
      Toast: {
        Success: 'Success',
        Error: 'Error',
        SuccessDefaultBankAccountDetail: 'Set default bank account completed!',
        SuccessRemoveDefaultBankAccountDetail:
          'Remove default bank account completed!',
      },
      Branch: 'Branch',
    },

    Gender: {
      Male: 'Male',
      Female: 'Female',
      NotSpecified: 'Not Specified',
    },
    User: {
      Username: 'Username',
      Name: 'Name',
      Prefix: 'Prefix',
      FirstName: 'First Name',
      LastName: 'Last Name',
      Gender: 'Gender',
      Email: 'Email',
      Phone: 'Phone',
      Role: 'Role',
      Company: 'Company',
      Organization: 'Organization',
      Branch: 'Branch',
      Department: 'Department',
      Position: 'Position',
      CreateOn: 'Create On',
      General: 'General',
      Security: 'Security',
      AccountSettings: 'Account Settings',
      OldPassword: 'Old Password',
      NewPassword: 'New Password',
      ReNewPassword: 'Re-new Password',
      IsActive: 'Active',
      IsVerify: 'Verify',
      UserStatus: 'User Status',
      UserDetail: 'User Detail',
      Active: 'Active',
      InActive: 'Inactive',
      SelectRole: 'Select Role',
      SelectPrefix: 'Select Prefix',
      SelectGender: 'Select Gender',
      Cover: 'Cover',
    },

    Customer: {
      businessName: 'Business name',
      businessNameError: 'Business Name is required',
      contactInfo: 'Contact Information',
      dateOfBirth: 'Date of Birth',
      accountsReceivableRecord: 'Accounts Receivable Record',
      accountsPayableRecord: 'Accounts Payable Record',
      thaiName: 'Thai Name',
      contactName: 'Contact Name',
      individualOrLegalEntity: 'Individual or Legal Entity',
      JuristicPerson: 'Juristic Person',
      OrdinaryPerson: 'Ordinary Person',
      requiredError: 'This field is required',
      selectCountry: 'Select Country',
      country: 'Thailand',
      countryOther: 'Other Countries',
      idNumber: 'TAX ID/ID Card',
      contactType: 'Contact Type',
      officeType: 'Office Type',
      noOffice: 'No Office',
      headOffice: 'Head Office',
      office: 'Office',
      noCountry: 'None',
      branch: 'Branch',
      search: 'Search',
      branchCodeLabel: 'Branch Code',
      branchCodePlaceholder: 'Enter branch code',
      branchNameLabel: 'Branch Name',
      branchNamePlaceholder: 'Enter branch name',
      officeTypeLabel: 'Select organization type',
      pleaseSpecify: 'Please specify',
      nameLabel: 'Name',
      contactNameLabel: 'Contact Name',
      debtorLabel: 'Accounts Payable',
      creditorLabel: 'Accounts Receivable',
      personalInfo: 'Personal Information',
      primaryContact: 'Primary Contact',
      specifyContactInfo: 'Specify your basic contact information',
      firstName: 'First Name',
      lastName: 'Last Name',
      nickname: 'Nickname',
      ContactCode: 'Contact Code',
      UplineCode: 'Upline Code',
      FullName: 'Name',
      position: 'Position',
      nameRequiredError: 'First Name is required',
      lastNameRequiredError: 'Last Name is required',
      nicknameRequiredError: 'Nickname is required',
      positionRequiredError: 'Position is required',
      contactChannelInfo: 'Contact Channel Information',
      centralContactInfo: 'Central Contact Information',
      indirectContact: 'In case you cannot be contacted directly',
      centralPhoneNumber: 'Central Phone Number',
      faxNumber: 'Fax Number',
      centralEmail: 'Central Email',
      syntaxError: 'Mistakes in using the language',
      website: 'Website',
      gbPhoneRequiredError: 'Central Phone Number is required',
      gbFaxRequiredError: 'Fax Number is required',
      gbEmailRequiredError: 'Central Email is required',
      gbSiteRequiredError: 'Website is required',
      office_type: 'Office Type is required',
      debtorRq: 'Accounts Receivable Type is required',
      creditorRq: 'Accounts Payable Type is required',
      phone: 'Phone Number',
      email: 'Email',
      emailRequiredError: 'Email is required',
      phoneRequiredError: 'Phone Number is required',
      fillOutCompletely: 'Please fill out the information completely.',
      invalidAllZeroBranchNumber: 'Cannot use 00000 as branch number.',
      branchNameRequiredError: 'Branch Name is required.',
      duplicateTaxID:
        'This number is already used for another tax invoice address.',
      BuyHistory: 'Buy History',
      SellHistory: 'Sell History',
    },
    Address: {
      AlertAddr: 'Please complete and check for accuracy.',
      addrInfo: 'Address Infomation',
      registeredAddress: 'Tax Invoice Address',
      mailingAddress: 'Shipping Address',
      contactPerson: 'Contact Person',
      address: 'Address',
      addressDetails: 'Number - Alley/Lane, Road or District',
      addressDetailsNote: 'Please specify',
      country: 'Country',
      copy: 'Same as Tax Invoice Address',
      thailand: 'Thailand',
      india: 'India',
      thailand2: 'Thailand (2)',
      province: 'Province',
      bangkok: 'Bangkok',
      startingPoint: 'Starting Point',
      district: 'District',
      subdistrict: 'Subdistrict',
      postalCode: 'Postal Code',
      cNameRequiredError: 'Contact Person is required.',
      cAddressRequiredError: 'Address is required.',
      cLocalRequiredError: 'Local area is required.',
      cCityRequiredError: 'Province is required.',
      cDistrictRequiredError: 'District is required.',
      cSubDistrictRequiredError: 'Subdistrict is required.',
      cCountyRequiredError: 'Province is required.',
      cPostcodeRequiredError: 'Postal code is required.',
      cPhoneRequiredError: 'Contact Phone is required',
      selectCountry: 'Select Country',
      selectProvince: 'Select Province',
      selectDistrict: 'Select District',
      selectSubDistrict: 'Select Sub District',
      isDefault: 'Default',
      setAsDefault: 'Set As Default',
      contactPhone: 'Contact Phone',
      addNewAddress: 'Add New Address',
      editSendShippingAddress: 'Edit Shipping Address for sending goods',
      editReceiveShippingAddress: 'Edit Shipping Address for Receiving goods',
      cancelEditAddress: 'Cancel Edit Address',
    },

    Region: {
      Title: 'Region',
      Northern: 'Northern Region',
      Central: 'Central Region',
      Northeastern: 'Northeastern Region',
      Western: 'Western Region',
      Eastern: 'Eastern Region',
      Southern: 'Southern Region',
    },

    Bank: {
      reftext: 'Bank Information',
      bankName: 'Bank Name',
      bankGroupRequired: 'Bank group is required',
      branch: 'Branch',
      bankAddressRequired: 'Bank address is required',
      accountNumber: 'Account Number',
      bankIdRequired: 'Bank ID is required',
      accountName: 'Account Name',
      bankNameRequired: 'Bank name is required',
      accountType: 'Account Type',
      savings: 'Savings',
      current: 'Current',
      bankTypeRequired: 'Bank type is required',
    },
    BookingCalendar: {
      Modal: {
        Header: 'Event Booking',
        EventName: 'Event Name',
        Customer: 'Customer',
        Location: 'Location',
        Date: 'Date',
        EventColor: 'Event Color',
        CancelEvent: 'Cancel Event',
        PeriodTime: 'Period Time',
        Remarks: 'Remarks',
        StartTime: 'Start Time',
        EndTime: 'End Time',
        CreateEvent: 'Create Event',
        UpdateEvent: 'Update Event',
        ConfirmCreate: 'Are you sure to create this event?',
        ConfirmUpdate: 'Are you sure to update this event?',

        Error: {
          EventColor: 'Please enter Event Color',
          Customer: 'Please enter customer name',
          Location: 'Please enter Location',
          EventName: 'Please enter Event Name',
          Time: 'Please enter time',
        },
        EditPastEvent: 'Cant edit event in the past',
      },
      Approval: {
        Event: 'Event',
        Remarks: 'Remarks',
        Time: 'Time',
        Customer: 'Customer',
        Status: 'Status',
        Action: 'Action',
      },
      Status: {
        WaitApprove: 'Wait Approve',
        Approved: 'Approved',
        Reject: 'Reject',
        None: 'None',
      },
    },

    Branch: {
      Modal: {
        EditBranch: 'Edit Branch',
        CreateBranch: 'Create Branch',
        CancelCreateTitle: 'Cancel Create Branch',
        CancelCreate: 'Are you sure to cancel create branch?',
        CancelEditTitle: 'Cancel Edit Branch',
        CancelEdit: 'Are you sure to cancel edit branch?',
        ConfirmEditTitle: 'Confirm Edit Branch',
        ConfirmEdit: 'Are you sure to edit branch?',
        ConfirmCreateTitle: 'Confirm Create Branch',
        ConfirmCreate: 'Are you sure to create branch?',

        BranchName: {
          Label: 'Branch Name',
          Placeholder: 'Enter branch name',
          Error: 'Please enter branch name',
        },
        BranchAddressNumber: {
          Label: 'Branch Address Number',
          Placeholder: 'Enter branch address number',
          Error: 'Please enter branch address number',
        },
        BranchPhone: {
          Label: 'Branch Phone',
          Placeholder: 'Enter branch phone',
          Error: 'Please enter branch phone',
        },
        BranchEmail: {
          Label: 'Branch Email',
          Placeholder: 'Enter branch email',
          Error: 'Please enter branch email',
        },
        BranchOpenDate: {
          Label: 'Branch Open Date',
          Placeholder: 'Enter branch open date',
          Error: 'Please enter branch open date',
        },
        BranchCloseDate: {
          Label: 'Branch close date',
          Placeholder: 'Enter branch close date',
          Error: 'Please enter branch close date',
        },
        BranchMapUrl: {
          Label: 'Branch Map Url',
          Placeholder: 'Enter branch map url',
          Error: 'Please enter branch map url',
        },
        BranchColor: {
          Label: 'Branch Color',
          Placeholder: 'Select a branch color',
          Error: 'Please select a branch color',
        },
        BranchPostCode: {
          Label: 'Branch Postal Code',
          Placeholder: 'Enter branch post code',
          Error: 'Postal code is required',
        },
        BranchImage: {
          Label: 'Branch Image',
          Error: 'Please select a branch image',
        },
        Weekday: {
          Label: 'Weekday',
          Open: 'Opening Time (Mon.-Fri.)',
          Placeholder: 'Enter time',
          Start: 'Start Time',
          End: 'End Time',
          Error: 'Please enter time',
        },
        Weekend: {
          Label: 'Weekend (Sat.-Sun.)',
          Start: 'Start Time',
          End: 'End Time',
          Error: 'Please enter time',
        },
        PastTimeError: 'Date or time already passed',
      },
    },
    Invoice: {
      item: 'Invoice Settings',
      sellPaymentSettingLabel:
        'Set Payment According to Issued Invoices (Sales)',
      sellPaymentSettingOptions: {
        settingByCompany: 'Set according to company settings',
        afterInvoiceXDays: 'X days after invoice issuance',
        endOfMonthInvoiceDate: 'End of month of invoice issuance',
        endOfMonthNextMonth: 'End of next month',
      },
      buyPaymentSettingLabel:
        'Set Payment According to Recorded Expenses/Received Invoices (Purchases)',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      sellreq: 'set_sell is required.',
      buyreq: 'set_buy is required.',
    },
    iTem: {
      Group: 'Group is parents of Category',
      Validate: ' * Please choose',
      Cate: 'Category is parents of Sub-category',
      subCate: 'Sub-category is sub detail of Category',
      Number: ' * Number only',
      Media: 'Files Limit',
      MediaLimit: ' * Need to be less than 5 items',
    },
    MLM: {
      YourCrew: 'Your Team',
      SVFormCrew: 'SV from Team',
      BonusFormCrew: 'Bonus from Team',
      OverviewCrew: 'Your team overview',
      TodayBonusFromYourCrew: 'Total bonus today from your team',
      Bonus: 'Bonus (Baht)',

      HighestBonusIn: 'Max bonus within',
      HighestSalesIn: 'Hightest sales within',
      HighestSalesInThisMonth: 'Hightest sales within this month',
      HighestSalesInThisWeek: 'Hightest sales within this week',
      ThisMonth: 'This month',
      ThisWeek: 'This week',
      PersonalBonus: 'Personal Bonus',
      DownlineBonus: 'Downline Bonus',
      SystemBonus: 'System Bonus',
      TotalIncome: 'Your Total Income',
      JoinWhen: 'Joined date',
      Period: {
        Title: 'Period',
        Year: 'Year',
        Month: 'Month',
        Start: 'Start',
        End: 'End',
        Type: 'Type',
        From: 'From',
        To: 'To',
        Date: 'Date',
        Editor: 'Edit By',
        Current: 'Current Sale Period',
        Setting: 'Sale Period Setting',
        HalfOfMonth: 'Every 15th. ( 1st-15th, 16th-Last day of month )',
        EndOfMonth: 'End of month',
        SendTo: 'Send to',
      },
      Member: 'Member',
      NewMember: 'New Member',
      ExistMember: 'Existing Member',
      TotalMember: 'Total Member',
      SaleTotal: 'Sale Total',
      MemberUnit: 'Member',
      Result: 'Result',
      Filter: 'Filter',
      MemberDemographicMap: 'Member Demographic Map',
      Configuration: 'MLM Configuration',
      RankManage: 'Rank Manage',
      SalePeriodManage: 'Sale Period Manage',
      SalePeriodHistory: 'Sale Period History',
      SettingHistory: 'Setting History',
      HighestSV: 'Highest SV',
      LowestSV: 'Lowest SV',
      SystemBonusCondition: {
        PresonalSV30K: 'within 1 Period',
        JoinEven100Percent: 'Join 100% Event',
        MPBonus: 'MP Bonus',
      },
    },
    Document: {
      QuotationDraft: 'Quotation Draft',
      Quotation: 'Quotation',
      SaleOrder: 'Sale Order',
      Invoice: 'Invoice',
      Invoice_Tax: 'Invoice/Tax Invoice',
      Tax: 'TAX Invoice',
      DebitNote: 'Debit Note',
      CreditNote: 'Credit Note',
      ReturnSlip: 'Return Slip',
      Receipt: 'Receipt',
      IV_TX_RE: 'Invoice/Tax Invoice/Receipt',
      PurchaseRequest: 'Purchase Request',
      PurchaseOrder: 'Purchase Order',
      SeparateGR: 'Create Separate Goods Receive',
      SeparateIV: 'Create Separate Invoice',
      GoodsReceive: 'Goods Receive',
      goodRecieve: 'Goods Receive',
      fullValue: 'Full value',
      writtenOff: 'Amount written off from this doc',
    },
    BreadCrumbs: {
      Manage: {
        Brand: 'Brand',
        Group: 'Group',
        Category: 'Category',
        SubCategory: 'Sub Category',
        Class: 'Class',
        SubClass: 'Sub Class',
        Item: 'Item',
        ItemSet: 'Item Set',
        Customer: 'Manage Customer',
        User: 'Manage Users',
        Role: 'Manage Role',
        Trash: 'Trash',
        BankAccount: 'Bank Account',
      },
      CMS: {
        Banner: 'Manage Banner',
        banner: ' Banner',
        News: 'Manage News & Event',
        news: ' News & Event',
        PDPA: 'Manage Privacy Policy Page',
        PrivacyPolicy: ' Privacy Policy Page',
        pdpa: ' PDPA',
        Event: 'Manage Event',
        event: ' Event',
        cookiePolicy: 'Manage Cookie Policy Page',
        CookiePolicy: ' Cookie Policy',
        termsCondition: 'Manage Terms Condition Page',
        TermsCondition: ' Terms & Condition',
        shoppingCart: 'Manage Shopping Cart',
        ShoppingCart: ' Shopping Cart',
        signIn: 'Manage Sign In',
        SignIn: ' Sign In',
        Contact: 'Manage Contact Page',
        contact: ' Contact',
        About: 'Manage About Page',
        about: ' About',
        Home: 'Manage Home Page',
        home: ' Home',
        Product: 'Manage Product Page',
        ProductLanding: ' Product',
        LandingPage: 'Manage News Landing Page',
        landingPage: ' Landing Page',
        CreateNews: 'Create News & Event',
        SellerManageRegulation: 'Seller Management Regulation Page',
        SellerManagementRegulation: ' Seller Management Regulation',
        FAQ: 'Manage FAQ',
        Categories: 'Manage Categories',
        categories: 'Categories',

        newsEvent: 'Manage News and Event Page',
        NewsEvent: ' News and Event',
        NewsEventLandingPage: 'News and Event Landing Page',

        consumerReview: 'Manage Consumer Review Page',
        ConsumerReview: ' Consumer Review',
        ConsumerReviewLandingPage: 'Consumer Review Landing Page',

        newsReview: 'Manage Consumer Review Page',
        NewsReview: ' Consumer Review',
        NewsReviewLandingPage: 'Consumer Review Landing Page',

        companyVideo: 'Manage Company Video Page',
        CompanyVideo: ' Company Video',
        CompanyVideoLandingPage: 'Company Video Landing Page',

        newsVideo: 'Manage Company Video Page',
        NewsVideo: ' Company Video',
        NewsVideoLandingPage: 'Company Video Landing Page',

        RepoveTools: {
          Landing: {
            Title: 'Manage Repove Tools Landing Page',

            BannerTitle: 'Banner Title',
            BannerDescription: 'Banner Description',
            BannerBackground: 'Banner Background',

            TPBannerTitle: 'Technology and Product Banner Title',
            TPBannerDescription: 'Technology and Product Banner Description',
            TPBannerBackground: 'Technology and Product Banner Background',

            OurCompanyBannerTitle: 'Our Company Banner Title',
            OurCompanyBannerDescription: 'Our Company Banner Description',
            OurCompanyBannerBackground: 'Our Company Banner Background',

            BonusPlanBannerTitle: 'Bonus Plan Banner Title',
            BonusPlanBannerDescription: 'Bonus Plan Banner Description',
            BonusPlanBannerBackground: 'Bonus Plan Banner Background',
          },
          Branch: {
            Title: 'Manage Branch',

            CoverImage: 'Cover Image',
            Name: 'Name',
            Subname: 'Sub Name',
            Detail: 'Detail',
          },
          BonusPlan: {
            Title: 'Manage Bonus Plan',

            SalePeriodDescription: 'Sale Period Description',
            saleValueDescription: 'Sale Value Description',

            APBackground: 'Active Provider Background',
            MPBackground: 'Master Provider Background',

            APMainBannerBackground: 'Active Provider Main Banner Background',
            APSubBannerBackground: 'Active Provider Sub Banner Background',
            APSubBannerBackgroundMobile:
              'Active Provider Sub Banner Background Mobile',

            APBannerTitle: 'Active Provider Banner Title',
            APBannerSubtitle: 'Active Provider Banner Subtitle',

            LeftBannerBackground: 'Left Banner Background',
            RightBannerBackground: 'Right Banner Background',

            LeftBannerDescription: 'Left Banner Description',
            RightBannerDescription: 'Right Banner Description',
          },
          CompanyCard: {
            Title: 'Manage Affiliated Companies',

            CoverImage: 'Logo',
            Type: 'Company Type',
            Name: 'Name',
            Detail: 'Detail',
          },
          Detail: {
            Title: 'Manage Our company Detail',

            BannerImage: 'Banner Image',
            BannerTitle: 'Banner Title',
            BannerDescription: 'Banner Description',
            BannerSubdescription: 'Banner Sub Description',

            Video: 'Company Video',

            ACImage: 'Affilated Companies Image',
            ACLogo: 'Affilated Companies Logo',
            ACTitle: 'Affilated Companies Title',
            ACName: 'Affilated Companies Name',
            ACDetail: 'Affilated Companies Detail',

            AnnualSale: 'Annual Sale',
            Branch: 'Branch in Korean',
            Subscription: 'Subscription',

            TimelineTitle: 'Timeline Banner Title',
            TimelineDescription: 'Timeline Banner Description',
            TimelineImage: 'Timeline Banner Image',

            TargetBannerTitle: 'Target Banner Title',
            TargetIconOne: 'Target Icon one',
            TargetTitleOne: 'Target Title one',
            TargetIconTwo: 'Target Icon two',
            TargetTitleTwo: 'Target Title two',
            TargetIconThree: 'Target Icon three',
            TargetTitleThree: 'Target Title three',
            TargetDescription: 'Target Banner Description',
          },
          Timeline: {
            Title: 'Manage Timeline',

            Year: 'Year Timeline',
            Description: 'Timeline Description',
            Month1: 'Month 1',
            Month2: 'Month 2 ',
            Month3: 'Month 3',
            Month4: 'Month 4',
            Month5: 'Month 5',
            Desecription1: 'Desecription 1',
            Desecription2: 'Desecription 2',
            Desecription3: 'Desecription 3',
            Desecription4: 'Desecription 4',
            Desecription5: 'Desecription 5',
          },
          Booster: 'Manage Booster Page',
          Serum: 'Manage Serum Page',
          Cream: 'Manage Cream Page',
          SunProtector: 'Manage Sun Protector Page',
          Bodywash: 'Manage Bodywash Page',
          HydroethosomeHighlightTech: {
            Title: 'Hydroethosome Highlight Tech',
            Content: 'Content',

            Name: 'Name',
            Subtitle: 'Sub Title',

            Icon: 'Icon',
            Description: 'Description',
          },
          TechAndProductLanding: {
            Title: 'Manage Technology and Product Landing Page',

            TechBannerTitle: 'Tech Banner Title',
            TechBannerDescription: 'Tech Banner Description',
            TechBannerBackground: 'Tech Banner Background',

            TechTitleOne: 'Tech Title One',
            TechDescriptionOne: 'Tech Description One',
            TechBackgroundOne: 'Tech Background One',

            TechTitleTwo: 'Tech Title Two',
            TechDescriptionTwo: 'Tech Description Two',
            TechBackgroundTwo: 'Tech Background Two',

            TechTitleThree: 'Tech Title Three',
            TechDescriptionThree: 'Tech Description Three',
            TechBackgroundThree: 'Tech Background Three',

            TechTitleFour: 'Tech Title Four',
            TechDescriptionFour: 'Tech Description Four',
            TechBackgroundFour: 'Tech Background Four',

            ProductBannerTitle: 'Product Banner Title',
            ProductBannerDescription: 'Product Banner Description',
            ProductBannerBackground: 'Product Banner Background',

            ProductTitleOne: 'Product Title One',
            ProductBackgroundOne: 'Product Background One',

            ProductTitleTwo: 'Product Title Two',
            ProductBackgroundTwo: 'Product Background Two',

            ProductTitleThree: 'Product Title Three',
            ProductBackgroundThree: 'Product Background Three',

            ProductTitleFour: 'Product Title Four',
            ProductBackgroundFour: 'Product Background Four',

            ProductTitleFive: 'Product Title Five',
            ProductBackgroundFive: 'Product Background Five',
          },
          Technology: {
            Hydroethosome: 'Manage Hydroethosome',
            HydroethosomeEtcTech: 'Manage Hydroethosome Etc Tech',
            HydroethosomeHighlight: 'Manage Hydroethosome Highlight Tech',
            HydroethosomeDetail: 'Manage Hydroethosome Detail',
            Hydropartisome: {
              Title: 'Manage Hydropartisome',

              BannerTitle: 'Banner Title',
              BannerDescription: 'Banner Description',
              BannerImage: 'Banner Image',

              TechParagraph1: 'Paragraph Title',
              TechParagraph2: 'Paragraph one',
              TechParagraph3: 'Paragraph two',
              TechParagraph4: 'Paragraph three',

              Substance1: 'Chemical one',
              Substance2: 'Chemical two',
              Substance3: 'Chemical three',

              Example: 'Example Image',

              PropertyIcon1: 'Property Icon one',
              PropertyDetail1: 'Property Detail one',
              PropertyIcon2: 'Property Icon two',
              PropertyDetail2: 'Property Detail two',
              PropertyIcon3: 'Property Icon three',
              PropertyDetail3: 'Property Detail three',
              PropertyIcon4: 'Property Icon four',
              PropertyDetail4: 'Property Detail four',
              PropertyIcon5: 'Property Icon five',
              PropertyDetail5: 'Property Detail five',
            },
            Hydroextraction: {
              Title: 'Manage Hydroextraction',

              BannerTitle: 'Banner Title',
              BannerSubtitle: 'Banner Sub Title',
              BannerImage: 'Banner Image',
              BannerDescription: 'Banner Description',

              StepLabel1: 'Step one Label',
              StepTitle1: 'Step one Title',
              StepDescription1: 'Step one Description',
              StepImage1: 'Step one Image',

              StepLabel2: 'Step two Label',
              StepTitle2: 'Step two Title',
              StepDescription2: 'Step two Description',
              StepImage2: 'Step two Image',

              StepLabel3: 'Step three Label',
              StepTitle3: 'Step three Title',
              StepDescription3: 'Step three Description',
              StepImage3: 'Step three Image',

              StepLabel4: 'Step four Label',
              StepTitle4: 'Step four Title',
              StepDescription4: 'Step four Description',
              StepImage4: 'Step four Image',

              StepLabel5: 'Step five Label',
              StepTitle5: 'Step five Title',
              StepDescription5: 'Step five Description',
              StepImage5: 'Step five Image',

              StepLabel6: 'Step six Label',
              StepTitle6: 'Step six Title',
              StepDescription6: 'Step six Description',
              StepImage6: 'Step six Image',

              ExtractionTitle1: 'Extraction one Title',
              ExtractionImage1: 'Extraction one Image',

              ExtractionTitle2: 'Extraction two Title',
              ExtractionImage2: 'Extraction two Image',

              ExtractionTitle3: 'Extraction three Title',
              ExtractionImage3: 'Extraction three Image',

              ExtractionTitle4: 'Extraction four Title',
              ExtractionImage4: 'Extraction four Image',

              ExtractionTitle5: 'Extraction five Title',
              ExtractionImage5: 'Extraction five Image',

              ExtractionDescription: 'Extraction Description',

              ExtractionDetailImage1: 'Extraction Detail Image one',
              ExtractionDetailScale1: 'Extraction Detail Scale one',
              ExtractionDetailTitle1: 'Extraction Detail Title one',
              ExtractionDetailSubtitle1: 'Extraction Detail Subtitle one',
              ExtractionDetailDescription1: 'Extraction Detail Description one',
              Ex1PropertyIcon1: 'Extraction one Property Icon one',
              Ex1PropertyTitle1: 'Extraction one Property Title one',
              Ex1PropertyIcon2: 'Extraction one Property Icon two',
              Ex1PropertyTitle2: 'Extraction one Property Title two',
              Ex1PropertyIcon3: 'Extraction one Property Icon three',
              Ex1PropertyTitle3: 'Extraction one Property Title three',
              Ex1PropertyIcon4: 'Extraction one Property Icon four',
              Ex1PropertyTitle4: 'Extraction one Property Title four',

              ExtractionDetailImage2: 'Extraction Detail Image two',
              ExtractionDetailScale2: 'Extraction Detail Scale two',
              ExtractionDetailTitle2: 'Extraction Detail Title two',
              ExtractionDetailSubtitle2: 'Extraction Detail Subtitle two',
              ExtractionDetailDescription2: 'Extraction Detail Description two',
              Ex2PropertyIcon1: 'Extraction two Property Icon one',
              Ex2PropertyTitle1: 'Extraction two Property Title one',
              Ex2PropertyIcon2: 'Extraction two Property Icon two',
              Ex2PropertyTitle2: 'Extraction two Property Title two',
              Ex2PropertyIcon3: 'Extraction two Property Icon three',
              Ex2PropertyTitle3: 'Extraction two Property Title three',
              Ex2PropertyIcon4: 'Extraction two Property Icon four',
              Ex2PropertyTitle4: 'Extraction two Property Title four',

              ExtractionDetailImage3: 'Extraction Detail Image three',
              ExtractionDetailScale3: 'Extraction Detail Scale three',
              ExtractionDetailTitle3: 'Extraction Detail Title three',
              ExtractionDetailSubtitle3: 'Extraction Detail Subtitle three',
              ExtractionDetailDescription3:
                'Extraction Detail Description three',
              Ex3PropertyIcon1: 'Extraction three Property Icon one',
              Ex3PropertyTitle1: 'Extraction three Property Title one',
              Ex3PropertyIcon2: 'Extraction three Property Icon two',
              Ex3PropertyTitle2: 'Extraction three Property Title two',
              Ex3PropertyIcon3: 'Extraction three Property Icon three',
              Ex3PropertyTitle3: 'Extraction three Property Title three',
              Ex3PropertyIcon4: 'Extraction three Property Icon four',
              Ex3PropertyTitle4: 'Extraction three Property Title four',

              ExtractionDetailImage4: 'Extraction Detail Image four',
              ExtractionDetailScale4: 'Extraction Detail Scale four',
              ExtractionDetailTitle4: 'Extraction Detail Title four',
              ExtractionDetailSubtitle4: 'Extraction Detail Subtitle four',
              ExtractionDetailDescription4:
                'Extraction Detail Description four',
              Ex4PropertyIcon1: 'Extraction four Property Icon one',
              Ex4PropertyTitle1: 'Extraction four Property Title one',
              Ex4PropertyIcon2: 'Extraction four Property Icon two',
              Ex4PropertyTitle2: 'Extraction four Property Title two',
              Ex4PropertyIcon3: 'Extraction four Property Icon three',
              Ex4PropertyTitle3: 'Extraction four Property Title three',
              Ex4PropertyIcon4: 'Extraction four Property Icon four',
              Ex4PropertyTitle4: 'Extraction four Property Title four',

              ExtractionDetailImage5: 'Extraction Detail Image five',
              ExtractionDetailScale5: 'Extraction Detail Scale five',
              ExtractionDetailTitle5: 'Extraction Detail Title five',
              ExtractionDetailSubtitle5: 'Extraction Detail Subtitle five',
              ExtractionDetailDescription5:
                'Extraction Detail Description five',
              Ex5PropertyIcon1: 'Extraction five Property Icon one',
              Ex5PropertyTitle1: 'Extraction five Property Title one',
              Ex5PropertyIcon2: 'Extraction five Property Icon two',
              Ex5PropertyTitle2: 'Extraction five Property Title two',
              Ex5PropertyIcon3: 'Extraction five Property Icon three',
              Ex5PropertyTitle3: 'Extraction five Property Title three',
              Ex5PropertyIcon4: 'Extraction five Property Icon four',
              Ex5PropertyTitle4: 'Extraction five Property Title four',
            },
            Partibiome: {
              Title: ' Manage Partibiome',

              BannerTitle: 'Banner Title',
              BannerSubtitle: 'Banner Sub Title',
              BannerImage: 'Banner Image',

              ContentDetail1: 'Content Detail one',
              ContentDetail2: 'Content Detail two',

              CardTitle1: 'Card Title one',
              CardDetail1: 'Card Detail one',
              CardTitle2: 'Card Title two',
              CardDetail2: 'Card Detail two',
              CardTitle3: 'Card Title three',
              CardDetail3: 'Card Detail three',
              CardTitle4: 'Card Title four',
              CardDetail4: 'Card Detail four',

              BottleTitle: 'Bottle Title',
              BottleSubtitle: 'Bottle Description',

              StepBackground: 'Extraction Step Background',
              StepIcon1: 'Extraction Step Icon one',
              StepHoverIcon1: 'Extraction Step Hover Icon one',
              StepTitle1: 'Extraction Step Mini Title one',
              StepSubtitle1: 'Extraction Step Title one',

              StepIcon2: 'Extraction Step Icon two',
              StepHoverIcon2: 'Extraction Step Hover Icon two',
              StepTitle2: 'Extraction Step Mini Title two',
              StepSubtitle2: 'Extraction Step Title two',

              StepIcon3: 'Extraction Step Icon three',
              StepHoverIcon3: 'Extraction Step Hover Icon three',
              StepTitle3: 'Extraction Step Mini Title three',
              StepSubtitle3: 'Extraction Step Title three',

              StepIcon4: 'Extraction Step Icon four',
              StepHoverIcon4: 'Extraction Step Hover Icon four',
              StepTitle4: 'Extraction Step Mini Title four',
              StepSubtitle4: 'Extraction Step Title four',

              StepIcon5: 'Extraction Step Icon five',
              StepHoverIcon5: 'Extraction Step Hover Icon five',
              StepTitle5: 'Extraction Step Mini Title five',
              StepSubtitle5: 'Extraction Step Title five',

              LeftImage: 'Lab Test Image Left',
              RightImage: 'Lab Test Image Right',
            },
          },
          Certification: {
            CardTitle: 'Certifications Card',
            FileTitle: 'Certifications Files',

            Name: 'Name',
            Title: 'Title',
            Detail: 'Detail',
            Image: 'Cover Image',
            CertImage: 'Certification Image',
            CardSelect: 'Certification Card',
            ActiveStatus: 'Active Status',
          },
        },
      },
    },
    FormQuotation: {
      SelectCustomer: 'Select Contact',
      SelectStockType: 'Select Stock Type',
      SelectItem: 'Select Product/Service',
      SelectShippingAddress: 'Select Shipping Address',
      SelectReceiveAddress: 'Select Receive Address',
      SelectBillingAddress: 'Select Billing Address',
      SelectIssueBillingAddress: 'Select Issue Tax Invoice Address',
      SelectPaymentMethod: 'Select ฺPayment Method',
      CreateDate: 'Issue Date',
      ExprieDate: 'Valid Until',
      DocCode: 'Document Code',
      DocRef: 'Document Ref.',
      SaleRef: 'Sale Reference',
      Price: {
        Title: 'Price',
        Select: 'Select Tax',
        Exclude: 'Exclude Tax',
        Include: 'Include Tax',
      },
      AddressTax: 'Tax Invoice Address',
      AddressShipping: 'Shipping Address',
      AddressReceive: 'Receive Address',
      AddressBilling: 'Billing Address',
      AddressIssueBilling: 'Issue Tax Invoice Address',
      Tel: 'Tel.',
      RecieveMoreProduct: {
        Label: 'Did you receive more products',
        Option1: 'Do not receive more products',
        Option2: 'Receive more products',
        Description1: "The product's quantity will not change",
        Description2: "The product's quantity will increase",
        Reason: 'Reason for issuing credit note',
      },
      SendMoreProduct: {
        Label: 'Changes in Debit Note',
        Option1: 'Do not send products',
        Option2: 'Send more products',
        Description1: "The product's quantity will not change",
        Description2: "The product's quantity will decrease",
        Reason: 'Reason for issuing debit note',
      },
      DescriptionOfReason: 'Description of reason',
    },
    SearchPanel: {
      General: {},
      Document: {
        CustomerName: 'Contact Name',
        DocCode: 'Document Code',
        CreateDateFrom: 'From create date',
        CreateDateTo: 'To create date',
        PriceFrom: 'From Grand Total',
        PriceTo: 'To Grand Total',
      },
    },
    ControlPanel: {
      AreYouSureToCancel: 'Are you sure to cancel ',
      AreYouSureTo: 'Are you sure to ',
      Confirm: 'Confirm ',
      Cancel: 'Cancel ',
      Create: 'create ',
      Edit: 'edit ',
    },
    Stock: {
      AreYouSureToCancel: 'Are you sure to cancel ',
      AreYouSureTo: 'Are you sure to ',
      Confirm: 'Confirm ',
      Cancel: 'Cancel ',
      Create: 'create ',
      Edit: 'Edit ',
      FG: 'FG',
      FGReserve: 'FG (Reserve)',
      FGAvailable: 'FG (Avaliable)',
      FOC: 'FOC',
      FOCReserve: 'FOC (Reserve)',
      FOCAvailable: 'FOC (Avaliable)',
      Reserve: 'Reserve',
      Avaliable: 'Avaliable',
      CenterIn: 'Center (In)',
      CenterOut: 'Center (Out)',
      Claim: 'Claim',
      Clear: 'Clear',
      Balance: 'Balance',
      Document: 'Document',
      StockType: 'Stock Type',
      TransferGoods: 'Transfer Goods',
      Type: 'Type',
      Quantity: 'Quantity',
      TransferIn: 'Transfer (In)',
      TransferOut: 'Transfer (Out)',
      Adjusted: 'Adjusted',
      Adjust: 'Adjust',
      Increase: 'Increase',
      Decrease: 'Decrease',
      Transfer: 'Transfer',
      From: 'From',
      To: 'To',
      Before: 'Before',
      After: 'After',
      Remarks: 'Remarks',
      Name: 'Name',
      Remain: 'Remain',
      Used: 'Used',
      QuantityForAdd: 'Quantity For Add',
      AdjustStock: 'Adjust Stock',
      AdjustSetStock: 'Adjust Set Stock',
      Purchase: 'Purchase',
      Sell: 'Sell',
      Return: 'Return',
      CancelReserve: 'Cancel Reserve',
      AdjustSet: 'AdjustSet',
      CreditNote: 'Credit Note',
      DebitNote: 'Debit Note',
    },
    Brand: {
      CancelCreate: ' to cancel the create ',
      Create: ' to create ',
      Edit: ' to edit ',
      Confirmsave: 'Confirm to save ',
      Confirmedit: 'Confirm to edit ',
      Confirmcreate: 'Confirm to create ',
      Save: ' to save ',
      CancelEditTitle: 'Cancel to edit Manage Brand',
      CancelEdit: 'Are you sure to cancel to edit Manage Brand?',
      CancelAddTitle: 'Cancel to add Manage Brand',
      CancelAdd: 'Are you sure to cancel to add Manage Brand?',
      ConfirmEditTitle: 'Confirm to edit Manage Brand',
      ConfirmEdit: 'Are you sure to edit Manage Brand?',
      ConfirmAddTitle: 'Confirm to add Manage Brand',
      ConfirmAdd: 'Are you sure to add Manage Brand?',
      Active: 'Are you sure to ',
      DeleteTitle: 'Delete ',
      Delete: 'Are you sure to delete ',
    },
    DocTable: {
      No: 'NO.',
      Item: 'Item/Service',
      Detail: 'Detail',
      Quantity: 'Quantity',
      Price: 'Price',
      Discount: 'Discount',
      StockType: 'Stock Type',
      PreTaxAmount: 'Amount',
      PreNewAmount: 'มูลค่าที่ถูกต้องก่อนภาษี',
      Select: 'Select Item',
      Wht: 'WHT',
      Button: {
        AddItem: 'Add Item',
        AddDes: 'Add Description',
        Reset: 'Reset',
      },
    },
    ViewTable: {
      No: '#',
      Code: 'Code',
      CustomerName: 'Customer Name',
      CustomerCode: 'Customer Code',
      EventName: 'Event Name',
      Status: 'Status',
      DateFrom: 'Date From',
      DateTo: 'Date To',
      GrandTotal: 'Grand Total',
      Actions: 'Actions',
      NoItemPreview: 'There is no item to preview',
      Name: 'Name',
      UpdateDate: 'Update Date',
      CreateBy: 'Create By',
      Role: 'Role',
      CreateDate: 'Create Date',
      By: 'By: ',
      Date: 'Date: ',
      UserName: 'User Name',
      Info: 'Info',
      InventoryStatus: 'Inventory Status',
      CodeName: 'Promotion name',
      RedeemCode: 'Redeem Code',
      StartDate: 'Start Date',
      EndDate: 'End Date',
      Amount: 'Amount',
      OrderCode: 'Order Code',
    },
    DocFooter: {
      Description: 'Description',
      Validate: "*Can't much than 9 lines",
      TotalDiscount: 'Total Discount',
      AmountVatExemp: 'Vat exempted amount',
      Vat: 'VAT',
      Baht: 'Baht',
      NetAmount: 'Net Amount',
      GrandTotal: 'Grand Total Amount',
      GrandTotalRepaid: 'Grand Total Repaid',
      AmountOfReferred: 'Total amount of referred tax invoice(s)',
      NewAmount: 'New amount',
      DeliveryPrice: 'Shipping Fee',
      Not: 'Not',
      SelectDiscountFormat: 'Select Discount Format',
    },
    ActionHistory: {
      No: 'No.',
      UsageActivity: 'Usage Activity ',
      Created: 'Created ',
      Paid: 'Paid ',
      Viewed: 'View ',
      At: 'at',
      Attach: ' attach',
      Update: 'Update ',
      Accept: 'Accept ',
      Void: 'Void ',
      Cancel: 'Cancel ',
      DocumentLog: 'Document Log',
      IssueTaxInvoice: 'Issue Tax Invoice',
      TaxInvoice: 'Number',
      GotReceipt: 'Got Receipt',
      VoidGotReceipt: 'Void Got Receipt',
      RegisterTaxInvoice: 'Register Tax Invoice',
      VoidTaxInvoice: 'Void Tax Invoice',
    },
    ContentDocument: {
      No: 'No.',
      Title: 'Title',
      Ref: 'Ref.',
      Print: 'Print',
      PrintDemo: 'Print (Demo)',
      Menu: 'Menu',
      Edit: 'Edit',
      CustomerInfo: 'Customer Info.',
      Customer: 'Customer',
      Address: 'Address',
      IssueDate: 'Issue Date',
      DueDate: 'Due Date',
      ValidUntil: 'Valid Until',
      PricingAndTaxSetting: 'Pricing and tax setting',
      CreditNoteInfo: 'Credit Note Info',
      DebitNoteInfo: 'Debit Note Info',
      PricingType: 'Pricing Type',
      Currency: 'Currency',
      ProductService: 'Product/Service',
      Description: 'Description',
      Quantity: 'Quantity',
      Unit: 'Unit',
      PriceQ: 'Price/QTY',
      CostQ: 'Cost/QTY',
      DiscQ: 'Disc./QTY',
      CostAmount: 'Cost Amount',
      PreVatAmount: 'Pre-VAT Amount',
      DocumentSummary: 'Document Summary',
      CurrentDocument: 'Current Document',
      Discount: 'Discount',
      VatItemValue: 'Pre-VAT Amount',
      VatAmount: 'VAT Amount',
      NetTotal: 'Net Total',
      Baht: 'THB',
      RemarkForCustomer: 'Remark for customer',
      HeadOffice: 'Head Office',
      Branch: 'Branch',
      Exclude: 'Exclude',
      Include: 'Include',
      WithholdingTaxAmount: 'Withholding Tax Amount',
      NetAmountToPay: 'Net Amount to Pay',
      Difference: 'Difference',
      TotalAmountOfReferredTaxInvoices:
        'Total amount of referred tax invoice(s)',
      NewAmount: 'New Amount',
      FixedPage: 'Fixed Page',
      CancelDocument: 'Cancel Document',
      VoidDocument: 'Void Document',
      CreateTaxInvoice: 'Create TAX Invoice',
      VoidGotReceipt: 'Void Got Receipt',
      Amount: 'Amount',
      Remain: 'Remain',
      Item: 'Item',
      ItemForItemSet: 'Item for Item Set',
      ItemSetValue: 'Item Set Value',
      ValueForItemSet: 'Price value for Item Set',
      ValueForItemSetCost: 'Cost value for Item Set',
      TotalCostPrice: 'Total Cost Price',
      TotalProductPrice: 'Total Product Price',
      InventoryStatus: 'Inventory Status',
      TransferCenterInAll: 'Transfer to Center Stock',
      SetInventory: 'Delivery Complete',
      EditRemarks: 'Edit Remarks',
      PaymentMethod: 'Payment Method',
      TrackingNumber: 'Tracking Number',
      TransportationChannel: 'Transportation Channel',
      ShippingInfo: 'Shipping Info.',
      Reciever: 'Reciever',
      ShippingAddress: 'Shipping Address',
      TaxInvoiceInfo: 'Tax Invoice Info.',
      TaxInvoiceCustomerName: 'Tax Invoice Customer Name',
      TaxInvoiceAddress: 'Tax Invoice Address',
    },
    SaleOrder: {
      ConfirmOrder: 'Confirm your Sale Order',
      CreateIVFormSO: 'Create Invoice from Sale Order',
      Approve: 'Approve',
      toApprove: ' to approve this ',
      this: '',
      Approvespace: 'Approve ',
      ApproveSale: 'Approve for Invoice',
      DetailSale: 'Are you confirm to approve for Invoice ?',
    },
    CreditDebit: {
      Create: 'Create ',
      EnterYour: 'Enter Your ',
      SelectDocument: 'Select Document',
      Business: 'Business',
      DocumentNo: 'Document No.',
      PleaseSelectBusiness: 'Please select Business',
      PleaseSelectDocumentNo: 'Please select Document No.',
      SelectBusiness: 'Select Business',
      SelectDocumentNo: 'Select Document',
      BusinessNotFound: 'Business not found',
      DocumentNotFound: 'Document not found',
      DebitNoteInfo: 'Debit Note Info',
      CreditNoteInfo: 'Credit Note Info',
      EnterDebitNoteInfo: 'Enter Debit Note Info',
      EnterCreditNoteInfo: 'Enter Credit Note Info',
      ReasonForIssuingCreditNote: 'Reason for issuing Credit Note',
      ReasonForIssuingDebitNote: 'Reason for issuing Debit Note',
      DescriptionOfReason: 'Description of reason',
    },
    Payment: {
      Baht: 'THB',
      ReceivePaymentRecord: 'Payment Record',
      PaymentRecord: 'Payment Record',
      TheTotalAmountOfReceivableDebt: 'The total amount of receivable debt is',
      PaymentRecieveMethod: 'Payment Recieve Method',
      PaymentMethod: 'Payment Method',
      PaymentAmount: 'Payment Amount',
      PaymentReceiveAmount: 'Payment Amount',
      Remark: 'Remark',
      Paid: 'Paid Value',
      MoneyPaymentRecieve: 'Money Payment Recieve',
      MoneyPayment: 'Money Payment',
      TaxWithheld: 'Tax Withheld',
      TotalPaymentRecieve: 'Total Payment Recieve',
      TotalPayment: 'Total Payment',
      RemainingAmount: 'Remaining Amount',
      Selectmethod: 'Please select a payment method.',
      dueDate: 'Due date',
      getPaidOn: 'Get paid on',
      getFullPaid: 'Get paid in full on',
      PaymentDate: 'Payment Date',
      FullPaid: 'Received full payment',
      paymentMethod: 'Payment method',
      payWithDoc: 'Payment with documents',
      stillPay: 'Still not getting paid in full',
      thisPrice: 'This price is',
      reCPay: 'Receive payment no.',
      cutOPay: 'Cut off payment with documents no.',
      Cash: 'Cash',
      cash: 'Cash',
      Transfer: 'Transfer',
      CreditDebit: 'Credit/Debit Card',
      Cheque: 'Cheque',
      Other: 'Other',
      Modal: {
        AccountNo: 'Account No.',
        CreateBankAccount: 'Create Bank Account',
        EditBankAccount: 'Edit Bank Account',
        BankAccount: {
          Label: 'Bank Account',
          Placeholder: 'Select a bank account',
          AddBankAccount: 'Add bank account',
          Error: 'Please select a bank account',
          CancelCreateTitle: 'Cancel Create Bank Account',
          CancelCreate: 'Are you sure to cancel create bank account?',
          ConfirmCreateTitle: 'Confirm Create Bank Account',
          ConfirmCreate: 'Are you sure to create bank account?',
          Bank: {
            Label: 'Bank',
            Placeholder: 'Select a bank',
            Error: 'Please select a bank',
          },
          AccountNumber: {
            Label: 'Account Number',
            Placeholder: 'Enter account number',
            Error: 'Please enter an account number',
          },
          BankBranch: {
            Label: 'Bank Branch',
            Placeholder: 'Enter bank branch',
            Error: 'Please enter a bank branch',
          },
          AccountName: {
            Label: 'Account Name',
            Placeholder: 'Enter account name',
            Error: 'Please enter an account name',
          },
          BankAccountType: {
            Label: 'Account Type',
            Saving: 'Savings',
            FixedDeposit: 'Fixed Deposit',
            Current: 'Current',
          },
        },
      },
    },
    DocumentPaper: {
      Print: 'Print',
      FixedPage: 'Fixed Page',
      HideObject: 'Hide Object',
      PageNumber: 'Page Number',
      Manuscript: 'Manuscript',
      Watermark: 'Watermark',
      TableColor: 'Table Color',
      Discount: 'Discount',
      WithheldTax: 'Withheld Tax',
      ClearAll: 'Clear All',
      BGColor: 'BG Color',
      IsOriginal: 'Original Doc',
      ShippingFee: 'Shipping Fee',
    },
    FormName: {
      QuotationForm: 'Quotation Form',
      QuotationDetail: 'Quotation Detail',

      SaleOrderForm: 'Sale Order Form',
      SaleOrderDetail: 'Sale Order Detail',

      InvoiceForm: 'Invoice Form',
      InvoiceDetail: 'Invoice Detail',

      TaxInvoiceForm: 'Tax Invoice Form',
      TaxInvoiceDetail: 'Tax Invoice Detail',

      ReceiptDetail: 'Receipt Detail',

      DebitNoteForm: 'Debit Note Form',
      DebitNoteDetail: 'Debit Note Detail',

      CreditNoteForm: 'Credit Note Form',
      CreditNoteDetail: 'Credit Note Detail',

      PurchaseRequestForm: 'Purchase Request Form',
      PurchaseRequestDetail: 'Purchase Request Detail',

      PurchaseOrder: 'Purchase Order Form',
      PurchaseOrderDetail: 'Purchase Order Detail',

      GoodsReceiveForm: 'Goods Receive Form',
      GoodsReceiveDetail: 'Goods Receive Detail',
    },
    Quotation: {
      ConfirmOrder: 'Confirm your Quotation',
      CancelCreateTitle: 'Cancel to create Quotation',
      CancelCreate: 'Are you sure to cancel to create Quotation?',
      CancelEditTitle: 'Cancel to edit Quotation',
      CancelEdit: 'Are you sure to cancel to edit Quotation?',
      SaveDraftTitle: 'Save Quotation Draft',
      SaveDraft: 'Are you sure to save Quotation Draft?',
      EditTitle: 'Edit Quotation',
      Edit: 'Are you sure to edit Quotation?',
      CreateTitle: 'Create Quotation',
      Create: 'Are you sure to create Quotation?',
      ApproveSale: 'Approve for Sale Order',
      DetailSale: 'Are you confirm to approve for Sale Order ?',
    },
    ManageItem: {
      CancelCreateTitle: 'Cancel to Create Item',
      CancelCreate: 'Are you sure to cancel to create Item?',
      CancelEditTitle: 'Cancel to Edit Item',
      CancelEdit: 'Are you sure to cancel to edit Item?',
      ConfirmCreateTitle: 'Confirm to Create Item',
      ConfirmCreate: 'Are you sure to create Item?',
      ConfirmEditTitle: 'Confirm to Edit Item',
      ConfirmEdit: 'Are you sure to edit Item?',
    },
    ManageItemSet: {
      CancelCreateTitle: 'Cancel to Create Item Set',
      CancelCreate: 'Are you sure to cancel to create Item Set?',
      CancelEditTitle: 'Cancel to Edit Item Set',
      CancelEdit: 'Are you sure to cancel to edit Item Set?',
      ConfirmCreateTitle: 'Confirm to Create Item Set',
      ConfirmCreate: 'Are you sure to create Item Set?',
      ConfirmEditTitle: 'Confirm to Edit Item Set',
      ConfirmEdit: 'Are you sure to edit Item Set?',
    },
    ManageGroup: {
      CancelCreateTitle: 'Cancel to create Group',
      CancelCreate: 'Are you sure to cancel to create Group?',
      CancelEditTitle: 'Cancel to edit Group',
      CancelEdit: 'Are you sure to cancel to edit Group?',
      ConfirmCreateTitle: 'Confirm to create Group',
      ConfirmCreate: 'Are you sure to create Group?',
      ConfirmEditTitle: 'Confirm to edit Group',
      ConfirmEdit: 'Are you sure to edit Group?',
    },
    ManageCategory: {
      CancelCreateTitle: 'Cancel to create Category',
      CancelCreate: 'Are you sure to cancel to create Category?',
      CancelEditTitle: 'Cancel to edit Category',
      CancelEdit: 'Are you sure to cancel to edit Category?',
      ConfirmCreateTitle: 'Confirm to create Category',
      ConfirmCreate: 'Are you sure to create Category?',
      ConfirmEditTitle: 'Confirm to edit Category',
      ConfirmEdit: 'Are you sure to edit Category?',
    },
    ManageSubCategory: {
      CancelCreateTitle: 'Cancel to create SubCategory',
      CancelCreate: 'Are you sure to cancel to create SubCategory?',
      CancelEditTitle: 'Cancel to edit SubCategory',
      CancelEdit: 'Are you sure to cancel to edit SubCategory?',
      ConfirmCreateTitle: 'Confirm to create SubCategory',
      ConfirmCreate: 'Are you sure to create SubCategory?',
      ConfirmEditTitle: 'Confirm to edit SubCategory',
      ConfirmEdit: 'Are you sure to edit SubCategory?',
    },
    ManageClass: {
      CancelCreateTitle: 'Cancel to create Class',
      CancelCreate: 'Are you sure to cancel to create Class?',
      CancelEditTitle: 'Cancel to edit Class',
      CancelEdit: 'Are you sure to cancel to edit Class?',
      ConfirmCreateTitle: 'Confirm to create Class',
      ConfirmCreate: 'Are you sure to create Class?',
      ConfirmEditTitle: 'Confirm to edit Class',
      ConfirmEdit: 'Are you sure to edit Class?',
    },
    ManageSubClass: {
      CancelCreateTitle: 'Cancel to create SubClass',
      CancelCreate: 'Are you sure to cancel to create SubClass?',
      CancelEditTitle: 'Cancel to edit SubClass',
      CancelEdit: 'Are you sure to cancel to edit SubClass?',
      ConfirmCreateTitle: 'Confirm to create SubClass',
      ConfirmCreate: 'Are you sure to create SubClass?',
      ConfirmEditTitle: 'Confirm to edit SubClass',
      ConfirmEdit: 'Are you sure to edit SubClass?',
    },
    ManageRole: {
      CancelCreateTitle: 'Cancel to create Role',
      CancelCreate: 'Are you sure to cancel to create Role?',
      CancelEditTitle: 'Cancel to edit Role',
      CancelEdit: 'Are you sure to cancel to edit Role?',
      ConfirmCreateTitle: 'Confirm to create Role',
      ConfirmCreate: 'Are you sure to create Role?',
      ConfirmEditTitle: 'Confirm to edit Role',
      ConfirmEdit: 'Are you sure to edit Role?',
      DeleteTitle: 'Delete Role',
      Delete: 'Are you sure to delete Role?',
      RoleName: 'Role Name',
      Active: 'Are you sure to ',
    },
    ManageUser: {
      CancelCreateTitle: 'Cancel to create User',
      CancelCreate: 'Are you sure to cancel to create User?',
      CancelEditTitle: 'Cancel to edit User',
      CancelEdit: 'Are you sure to cancel to edit User?',
      ConfirmCreateTitle: 'Confirm to create User',
      ConfirmCreate: 'Are you sure to create User?',
      ConfirmEditTitle: 'Confirm to edit User',
      ConfirmEdit: 'Are you sure to edit User?',
      DeleteTitle: 'Delete User',
      Delete: 'Are you sure to delete User?',
      ResetTitle: 'Reset Password',
      Reset: 'Are you sure to reset password?',
      Active: 'Are you sure to ',
    },
    CreditNote: {
      ConfirmCreateTitle: 'Confirm to create Credit Note',
      ConfirmCreate: 'Are you sure to create Credit Note?',
    },
    DebitNote: {
      ConfirmCreateTitle: 'Confirm to create Debit Note',
      ConfirmCreate: 'Are you sure to create Debit Note?',
    },
    GoodsReceive: {
      GetReceipt: 'Get Receipt',
      YouCanGetTheReceipt: 'You can get the Receipt',
    },
    Files: {
      NoFileChosen: 'No File Chosen',
      ChooseFile: 'Choose File',
    },
    Informative: {
      Description: 'Description',
      ActiveStatus: 'Active Status',
      Home: {
        BannerImage: 'Banner Image',
        BannerImageDescription: 'Resolution 1920px*730px',
        BannerVideo: 'Banner Video',
        BannerVideoDescription: 'Resolution 1920px*730px',
        UseBannerAsVideo: 'Use Banner as Video',
        WhoWeAreQuote: {
          Label: 'Who we are Quote',
          Description: 'Quote',
        },
        WhoWeAreDescription: {
          Label: 'Who we are Description',
          Description: 'Description',
        },
        WhoWeAreImage1: {
          Label: 'Who We Are Image 1',
          Description: 'Resolution: 260px*260px',
        },
        WhoWeAreImage2: {
          Label: 'Who We Are Image 2',
          Description: 'Resolution: 440px*640px',
        },
        WhoWeAreImage3: {
          Label: 'Who We Are Image 3',
          Description: 'Resolution: 480px*300px',
        },
        NewProductSection: 'New Product Section Description',
        NewProductBG: 'New Product Background',
        NewProductBGDescription: 'Resolution: 940px*750px',
        NewProductWordingButton: 'New Product Wording Button',
        WordingOnButton: 'Wording on button',
        NewProductURL: 'New Product URL',
        NewProductURLDescription: 'URL to redirect',
        OurTechnologyQuote: 'Our Technology Quote',
        OurTechnologyVideo: 'Our Technology Video',
        OurTechnologyVideoDescription: 'Resolution: 1280px*600px',
        MemberPromotionImage1: 'Member Promotion Image 1',
        MemberPromotionImage1Description: 'Resolution: 660px*447px',
        MemberPromotionImage2: 'Member Promotion Image 2',
        MemberPromotionImage2Description: 'Resolution: 660px*447px',
      },
      About: {
        BannerTitle: 'Banner Title',
        MainWordingOnBanner: 'Main Wording on banner',
        BannerSubTitle: 'Banner Sub Title',
        SubWordingOnBanner: 'Sub Wording on banner',
        BannerImage: 'Banner Image',
        BannerImageDescription:
          'Banner Background Image, Resolution 1920px*450px',
        QuoteTitle1: 'Quote Title 1',
        QuoteDescription: 'Quote Description',
        QuoteImage1: 'Quote Image 1',
        QuoteImageDescription: 'Resolution 1280px*600px',
        AboutDescription1: 'About Description 1',
        DescriptionOnLeftSide: 'Description on left side',
        AboutDescription2: 'About Description 1',
        DescriptionOnRightSide: 'Description on right side',
        AboutImage1: 'About Image 1',
        AboutImage1Description: 'Image on left side, Resolution 220px*220px',
        AboutImage2: 'About Image 2',
        AboutImage2Description: 'Image on middle side, Resolution 400px*600px',
        AboutImage3: 'About Image 3',
        AboutImage3Description: 'Image on right side, Resolution 510px*300px',
        BannerTitle2: 'Banner Title 2',
        BannerImage2: 'Banner Image 2',
        QuoteTitle2: 'Quote Title 2',
        QuoteVideo: 'Quote Video',
      },
      Contact: {
        BannerTitle: 'Banner Title',
        BannerTitleDescription: 'Wording in the middle of Banner',
        BannerImage: 'Banner Image',
        BannerImageDescription: 'Resolution 1920px*450px',
        VisitUs: 'Visit us',
        VisitUsDescription: 'Description of Office Location',
        CalltUs: 'Call us',
        CallUsDescription: 'Description of Store Location',
        EmailtUs: 'Email us',
        EmailUsDescription: 'Description of Call us directly',
        ContactFormImage1: 'Contact Form Image 1',
        ContactFormImage1Description: 'Resolution 510px*587px',
        ContactFormImage2: 'Contact Form Image 2',
        ContactFormImage2Description: 'Resolution 330px*387px',
      },
      NewsEvent: {
        NewsBanner: {
          Label: 'News Banner',
          Description: 'Banner Background',
        },
        NewsTitle: {
          Label: 'News Title',
          Description: 'News Title',
        },
        ConsumerReviewDate: {
          Label: 'Consumer Review Date',
          Description: 'Date of Consumer Review',
        },
        ConsumerReviewTitle: {
          Label: 'Consumer Review Title',
          Description: 'Title of Consumer Review',
        },
        ConsumerReviewImage1: {
          Label: 'Consumer Review Image 1',
          Description: '1st Image of Consumer Review',
        },
        Detail: {
          Label: 'Detail',
          Description: 'Detail of Consumer Review',
        },
        ConsumerReviewImage2: {
          Label: 'Consumer Review Image 2',
          Description: '2nd Image of Consumer Review',
        },
        ReviewerName: {
          Label: 'Reviewer Name',
          Description: 'Name of Reviewer',
        },
        URLButton: {
          Label: 'URL Button',
          Description: 'URL to redirect',
        },
        SocialMedia: {
          Label: 'Social Media',
          Description: 'Select Social Media',
        },
        CompanyVideo: {
          ContentDate: {
            Label: 'Content Date',
            Description: 'Date of Company Video',
          },
          VideoTitle: {
            Label: 'Video Title',
            Description: 'Title of Company Video',
          },
          VideoCoverImage: {
            Label: 'Video Cover Image',
            Description: 'Video Cover Image of Company Video',
          },
          Subtitle: {
            Label: 'Subtitle',
            Description: 'Subtitle of Company Video',
          },
          RepoveLive: {
            Label: 'Repove Live',
            Description: 'Check if is Live',
          },
          LiveDate: {
            Label: 'Live Date',
            Description: 'Date on Live',
          },
          MediaType: {
            Label: 'Media Type',
            Description: 'Video or Live',
          },
        },
      },
      SignIn: {
        BannerImage: {
          1: 'Banner Image 1',
          2: 'Banner Image 2',
        },
        BannerMobileImage: {
          1: 'Banner Mobile Image 1',
          2: 'Banner Mobile Image 2',
        },
        Description: 'Resolution 648px*785px',
      },
      HelpCenter: {
        PersonalSaleTitle: {
          Label: 'Personal Sale Title',
          Description: 'Title',
        },
        PersonalSaleDescription: {
          Label: 'Personal Sale Description',
          Description: 'Description',
        },
        DownlineSaleTitle: {
          Label: 'Downline Sale Title',
          Description: 'Title',
        },
        DownlineSaleDescription: {
          Label: 'Downline Sale Description',
          Description: 'Description',
        },
        SystemBonusTitle: {
          Label: 'System Bonus Title',
          Description: 'Title',
        },
        SystemBonusDescription: {
          Label: 'System Bonus Description',
          Description: 'Description',
        },
        PersonalBonusTable: {
          Label: 'Personal Sale Table Image',
          Description: 'Table image',
        },
        DownlineBonusTable: {
          Label: 'Downline Sale Table Image',
          Description: 'Table image',
        },
        SystemBonusTable: {
          Label: 'System bonus Table Image',
          Description: 'Table Image',
        },
        OverallBonusTable: {
          Label: 'Overall bonus Table Image',
          Description: 'Table Image',
        },

        Categories: {
          Label: 'Categories',
        },
      },
      Product: {
        BannerImage: {
          1: 'Banner Image 1',
          2: 'Banner Image 2',
        },
        BannerTitle: {
          Label: 'Banner Title',
          Description: 'Title on Banner Image 2',
        },
        Description: 'Resolution 1920px*450px',
      },
      PrivacyPolicy: {
        Date: {
          Label: 'Date',
          Description: 'Date of Privacy Policy',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Privacy Policy',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Privacy Policy',
        },
      },
      CookiePolicy: {
        Date: {
          Label: 'Date',
          Description: 'Date of Cookie Policy',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Cookie Policy',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Cookie Policy',
        },
      },
      TermsCondition: {
        Date: {
          Label: 'Date',
          Description: 'Date of Terms and Condition',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Terms and Condition',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Terms and Condition',
        },
      },
      SellerManagementRegulation: {
        Date: {
          Label: 'Date',
          Description: 'Date of Seller Management Regulation',
        },
        Title: {
          Label: 'Title',
          Description: 'Title of Seller Management Regulation',
        },
        Description: {
          Label: 'Description',
          Description: 'Description of Seller Management Regulation',
        },
      },
      RepoveToolsProduct: {
        PresenterImage: 'Presenter Image',
        PresenterImageDescription: 'Resolution 920px*670px',
        ProductImage: 'Product Image',
        ProductNameTH: 'Product name Thai',
        ProductNameTHDescription: 'Property names in Thai at the top',
        ProductNameEN: 'Product name English',
        ProductNameENDescription:
          'English product names below Thai product names',
        ProductPrice: 'Product Price',
        // ProductPriceDescription : 'Price',
        ProductVolume: 'Product Volume',
        ProductVolumeDetail: 'Product quantity next to price',
        ProductDetail: 'Product Detail',
        ProductDetailDiscription: 'Product details below product price',
        TechnologyDetails: 'Technology Details',
        TechnologyDetailsDiscription: 'Technology details on the left',
        FeatureTitle: 'Feature Title',
        FeatureTitleDiscription: 'Topic property title',
        OtherFeaturesTitle: 'Other Features Title',
        OtherFeaturesTitleDiscription: 'Other properties are listed below',
        FeaturIcon1: 'Feature Icon 1',
        FeaturIcon2: 'Feature Icon 2',
        FeaturIcon3: 'Feature Icon 3',
        FeaturIcon4: 'Feature Icon 4',
        FeaturIconDiscription: 'Resolution: 50px*50px',
        FeaturDetail1: 'Feature Detail 1',
        FeaturDetail2: 'Feature Detail 2',
        FeaturDetail3: 'Feature Detail 3',
        FeaturDetail4: 'Feature Detail 4',
        OtherFeaturesIcon1: 'Other Features Icon 1',
        OtherFeaturesIcon2: 'Other Features Icon 2',
        OtherFeaturesIcon3: 'Other Features Icon 3',
        OtherFeaturesIcon4: 'Other Features Icon 4',
        OtherFeaturesDetail1: 'Other Features Detail 1',
        OtherFeaturesDetail2: 'Other Features Detail 2',
        OtherFeaturesDetail3: 'Other Features Detail 3',
        OtherFeaturesDetail4: 'Other Features Detail 4',
        ComponentImage: 'Component Image',
        ComponentImageDescription: 'Resolution 920px*670px',
        ComponentDetails: 'Component Details',
        ComponentDetailsDiscription: 'Component details on the right',
        TestResults: 'Test results',
        TestResultsTablet: 'Test results Tablet',
        TestResultsMobile: 'Test results Mobile',
        BeforeAfterImage1: 'Before After Image 1',
        BeforeAfterImage2: 'Before After Image 2',
        BeforeAfterImage3: 'Before After Image 3',
        BeforeAfterImage4: 'Before After Image 4',
        BeforeAfterImageDiscription: 'Resolution 480px*250px',
        bodywash: {
          ProductDetailHeadIcon: 'Product Detail Head Icon',
          ProductDetailFacialSkinIcon: 'Product Detail Facial Skin Icon',
          ProductDetailbodyIcon: 'Product Detail Body Icon',
          ProductDetailIconDescription: 'Resolution 50px*50px',
          ProductDetailHeadTitle: 'Product Detail Head Title',
          ProductDetailHeadTitleDescription: 'Product Detail Head Title',
          ProductDetailFacialSkinTitle: 'Product Detail Facial Skin Title',
          ProductDetailTitleDescription: 'Title below product details',
          ProductDetailHeadDetail: 'Product Detail Head Detail',
          ProductDetailFacialSkinDetail: 'Product Detail Facial Skin Detail',
          ProductDetailbodyDetail: 'Product Detail Body Detail',
          ProductDetailDetailDescription: 'Title details',
          Label1: 'Feature Card Label 1',
          Label2: 'Feature Card Label 2',
          Label3: 'Feature Card Label 3',
          LabelDescription: 'Name label above icon',
        },
      },
      RepoveToolsTechnology: {
        BannerImg: 'Banner Image',
        BannerImgDescription: 'Resolution 1800px*600px',
        BannerDescription: 'Banner Description',
        BannerSubDescription: 'Banner Sub Description',
        BannerSubDescriptionDes:
          'The secondary banner description is below the banner description.',
        Hydroethosome: {
          QuoteTitle1: 'Quote Title 1',
          QuoteDescription: 'Quote Description',
          QuoteImage1: 'Quote Image 1',
          QuoteImageDescription: 'Resolution 400px*405px',
          FeatureCardIcon1: 'Feature card icon 1',
          FeatureCardIcon2: 'Feature card icon 2',
          FeatureCardIcon3: 'Feature card icon 3',
          FeatureCardIcon4: 'Feature card icon 4',
          FeatureCardIcon5: 'Feature card icon 5',
          FeatureCardIconDescription: 'Resolution 60px*60px',
          FeatureCardDetail1: 'Feature card detail 1',
          FeatureCardDetail2: 'Feature card detail 2',
          FeatureCardDetail3: 'Feature card detail 3',
          FeatureCardDetail4: 'Feature card detail 4',
          FeatureCardDetail5: 'Feature card detail 5',
          FeatureCardDetailDescription: 'Details of features below icon',
          OtherBasedImage1: 'Other Based Image 1',
          OtherBasedImage2: 'Other Based Image 2',
          OtherBasedImage3: 'Other Based Image 3',
          OtherBasedImage4: 'Other Based Image 4',
          OtherBasedImage5: 'Other Based Image 5',
          OtherBasedImage6: 'Other Based Image 6',
          OtherBasedImageDescription: 'Resolution 180px*180px',
          OtherBasedDetail1: 'Other Based Detail 1',
          OtherBasedDetail2: 'Other Based Detail 2',
          OtherBasedDetail3: 'Other Based Detail 3',
          OtherBasedDetail4: 'Other Based Detail 4',
          OtherBasedDetail5: 'Other Based Detail 5',
          OtherBasedDetail6: 'Other Based Detail 6',
          OtherBasedDetailDescription: 'Details of other bases below the image',
          hydroethosomeFunctionImg: 'Extract Image',
          hydroethosomeFunctionImgDescription: 'Resolution 350px*350px',
          hydroethosomeFunctionName: 'Extract Name',
          hydroethosomeFunctionDetail: 'Extract Detail',
          hydroethosomeFunctionSubDetail: 'Extract Sub Detail',
          hydroethosomeFunctionSubDetailDes:
            'Secondary extract details below Extract details',
          hydroFunctionCardIcon1: 'Substances Card Icon 1',
          hydroFunctionCardIcon2: 'Substances Card Icon 2',
          hydroFunctionCardIcon3: 'Substances Card Icon 3',
          hydroFunctionCardIcon4: 'Substances Card Icon 4',
          hydroFunctionCardIcon5: 'Substances Card Icon 5',
          hydroFunctionCardDescription: 'Resolution 40px*40px',
          hydroFunctionCardDetail1: 'Substances Card Detail 1',
          hydroFunctionCardDetail2: 'Substances Card Detail 2',
          hydroFunctionCardDetail3: 'Substances Card Detail 3',
          hydroFunctionCardDetail4: 'Substances Card Detail 4',
          hydroFunctionCardDetail5: 'Substances Card Detail 5',
          hydroFunctionCardDetailDescription:
            'Details of substances below icon',
        },
      },
    },

    Notification: {
      Title: 'Notification',
      StartDate: 'Start Date',

      Manage: 'Notification Manage',
      NotificationType: 'Notification Type',
      MemberType: 'Type',

      SendNow: 'Send Now',
      SendLater: 'Send Later',
    },
  },
};
